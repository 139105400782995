type FeatureSwitchName = `web_marketplace_${string}` | `api_marketplace_${string}`;

type LoggedOutSwitchMap = Record<
  FeatureSwitchName,
  {
    entityType: "parent" | "teacher";
    values: readonly ["off", "control", ...string[]] | readonly ["off", "on"];
  }
>;

type LoggedInSwitchMap = Record<
  FeatureSwitchName,
  {
    values: readonly ["off", "control", ...string[]] | readonly ["off", "on"];
  }
>;

export const LOGGED_OUT_SWITCH_MAP = {
  web_marketplace_trialbooking_step1_text_jun2024: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_create_account_without_password_jul2024_v3: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_new_homepage_aug2024_v2: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_email_first_trial_flow_sep2024: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_trial_perfect_tutor_sep2024: {
    entityType: "parent",
    values: ["off", "control", "variant1", "variant2"],
  },
} as const satisfies LoggedOutSwitchMap;

export const LOGGED_IN_SWITCH_MAP = {
  web_marketplace_render_decagon_widget_june: {
    values: ["off", "control", "test"],
  },
} as const satisfies LoggedInSwitchMap;
