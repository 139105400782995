import { Global } from "@mantine/core";
import FixBadPaths from "@web-monorepo/shared/router/FixBadPaths";
import { RouterNavigateProvider } from "@web-monorepo/shared/router/reactRouterNavigate";
import { MatomoConfiguredProvider } from "old/src/components/MatomoProvider";

import "core-js/features/object/from-entries";
import "@classdojo/web/polyfills";

// eslint-disable-next-line @web-monorepo/no-css-file-imports
import "@classdojo/web/pods/font/dojoText.css";

// eslint-disable-next-line @web-monorepo/no-css-file-imports
import "./_nessie.css";
// eslint-disable-next-line @web-monorepo/no-css-file-imports
import "./_app.css";

import { NessieThemeProvider } from "@classdojo/web/nessie";
import { ErrorHandlerContext } from "@classdojo/web/pods/errorHandling";
import AppTopLevelErrorBoundary from "@classdojo/web/pods/errorHandling/components/AppTopLevelErrorBoundary";
import ErrorBanner from "@classdojo/web/pods/errorHandling/components/ErrorBanner";
import { UserActionsHistory } from "@classdojo/web/pods/userActionsHistory";
import { setTranslateFunction } from "@classdojo/web/utils/translate";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { translate } from "@web-monorepo/shared/utils/translate";
import { useEffect, useState } from "react";
import useIsIOS from "src/hooks/useIsIOS";
import { theme as mantineTheme } from "UILibrary";
import { datadogRum } from "@datadog/browser-rum";
import { StoreProvider } from "old/src/store";
import { refreshIfOldVersion, scheduleRefresh } from "@web-monorepo/shared/utils/midnightRefresh";
import { Outlet, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import * as Telemetry from "@web-monorepo/telemetry";
import EnsureSessionAndLoggerContext from "src/components/EnsureSessionandLoggerContext";
import queryClient from "src/queryClient";
import { createWebErrorHandler, ignorableMarketplaceErrors } from "src/errorHandling";
import { DesignSystemVariables } from "@web-monorepo/dds";
import HelperWidgetProvider from "old/src/components/HelperWidgetProvider";
import { DevTools } from "src/utils/devTools";
import { QueryClientProvider } from "@tanstack/react-query";
import { setAppFeatureSwitchesConfig } from "src/hooks/useFeatureSwitches";
import marketplaceTheme from "src/utils/marketplaceTheme";
import { Theme } from "@classdojo/web/nessie/components/useColorTheme";

declare global {
  interface Window {
    unsupportedBrowser: boolean;
    appVersion: string;
    fbq?: (type: string, event: string, data?: { [key: string]: unknown }) => void;
  }
}

scheduleRefresh("marketplace");
refreshIfOldVersion({ buildAgeInDays: 3, releaseAgeInDays: 1 });

setAppFeatureSwitchesConfig();

const useRedirectOnSearchParams = () => {
  const { origin, pathname, search, hash } = window.location;

  // We use this here to force it to see and store whether or not the user came from iOS
  useIsIOS();

  if (!search) return { willRedirect: false };

  window.location.href = `${origin}${pathname}${hash || "#/"}${
    hash.indexOf("?") < 0 ? search : `&${search.substring(1)}`
  }`;
  return { willRedirect: true };
};

if (Config.nodeEnv === "production") {
  Telemetry.init({
    dsn: "https://4755818d459c24619e89b402acc95172@sentry.classdojo.com/11",
    tracing: {
      reactRouter: {
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      },
    },
  });
}
const App = () => {
  const userActionsHistory = new UserActionsHistory();
  if (typeof document !== "undefined") {
    userActionsHistory.listenToBrowserUrlChanges();
  }
  const [errorHandler] = useState(() => createWebErrorHandler(userActionsHistory));
  setTranslateFunction(translate);

  const { willRedirect } = useRedirectOnSearchParams();

  datadogRum.init({
    applicationId: "8b0e936a-3b99-4c0f-b5a0-3ea1ad8fd7fc",
    clientToken: "pub2cee79188ef4555058352c3f76d73ed5",
    site: "datadoghq.com",
    service: "tutor",
    env: "production",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: "1.0.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: (event) => {
      // Workaround for hash router.
      event.view.url = event.view.url.replace("/#", "");
      return true;
    },
  });

  return willRedirect ? null : (
    <ErrorHandlerContext.Provider value={errorHandler}>
      <NessieThemeProvider selectedTheme={marketplaceTheme as Theme}>
        <ErrorBanner useIsLocaleLoaded={() => false} />
        <AppTopLevelErrorBoundary errorHandler={errorHandler} excludeErrors={ignorableMarketplaceErrors}>
          <MantineProvider theme={mantineTheme} withGlobalStyles withNormalizeCSS>
            <StoreProvider>
              <QueryClientProvider client={queryClient}>
                <EnsureSessionAndLoggerContext>
                  <HelperWidgetProvider>
                    <Global
                      styles={{
                        WebkitFontSmoothing: "antialiased",
                        html: {
                          overflowX: "hidden",
                        },
                        body: {
                          overflowX: "hidden",
                          height: "100%",
                          minHeight: "100vh",
                          display: "flex",
                          flexDirection: "column",
                          "&, *": {
                            fontFamily: `"Dojo Text", ${mantineTheme.fontFamily} !important`,
                          },
                        },
                        p: {
                          margin: 0,
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#2C2A50",
                        },
                        "#root": {
                          overflowX: "hidden",
                          position: "relative",
                          backgroundColor: "white",
                          minHeight: "100vh",
                        },
                      }}
                    />
                    <MatomoConfiguredProvider>
                      <RouterNavigateProvider />
                      <DesignSystemVariables theme="light" />
                      <FixBadPaths />
                      <Notifications zIndex={2147483009} position="bottom-left" />
                      <Outlet />
                      <DevTools />
                    </MatomoConfiguredProvider>
                  </HelperWidgetProvider>
                </EnsureSessionAndLoggerContext>
              </QueryClientProvider>
            </StoreProvider>
          </MantineProvider>
        </AppTopLevelErrorBoundary>
      </NessieThemeProvider>
    </ErrorHandlerContext.Provider>
  );
};

export default App;
