import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { BodyText, DisplayText, HeadlineText, Button, LabelText } from "@classdojo/web/nessie";
import useRedirectToBooking from "src/hooks/useRedirectToBooking";

const Content = [
  {
    headline: "Setup a trial class",
    body: "Select your child’s grade level and the subject they need help with. Then pick a time that works for you.",
    number: "landing2023/number1.png",
    background: "landing2023/bg-step1@2x.png",
    width: 214,
    height: 288,
  },
  {
    headline: "Get matched.",
    body: "We’ll match your child with the ideal tutor based on their needs, interests, and aspirations.",
    number: "landing2023/number2.png",
    background: "landing2023/bg-step2@2x.png",
    width: 216,
    height: 237,
  },
  {
    headline: "Try it out.",
    body: "Your child meets their tutor for a 25-minute session. Afterwards, you’ll get an assessment and a plan for how to move forward.",
    number: "landing2023/number3.png",
    background: "landing2023/bg-step3@2x.png",
    width: 214,
    height: 257,
  },
];

interface GetStartedProps {
  showTopCTA?: boolean;
}

const GetStarted: React.FC<GetStartedProps> = ({ showTopCTA }: GetStartedProps) => {
  const redirectToBooking = useRedirectToBooking();

  return (
    <div sx={{ ...BgContainer, borderBottom: showTopCTA ? "none" : RAW_cssValue("solid 1px #F1F3F8") }}>
      {showTopCTA ? (
        <div
          sx={{
            maxWidth: 800,
            margin: "auto",
            textAlign: "center",
            paddingInline: "dt_m",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src="landing2023/dojo-tutor@2x.png" alt="Dojo Tutor" width={320} sx={{ marginTop: -60 }} />
          <DisplayText level={3} sx={{ ...sxHeading }}>
            Save $20 on your first class.
          </DisplayText>
          <HeadlineText as="h2" sx={{ ...sxHeadingDisplay, marginBottom: "dt_l" }}>
            Try 1-1 tutoring with an elite teacher
          </HeadlineText>
          <div sx={{ display: "flex", flexDirection: ["column", "column", "row-reverse"], gap: "dt_l" }}>
            <Button
              data-name="try-campaign-cta"
              onClick={redirectToBooking}
              sx={{ ...CTAButton, "&::after": { display: "none" }, minWidth: 200 }}
            >
              Try a class
            </Button>
            <div sx={{ textAlign: "center" }}>
              <strong sx={{ fontSize: RAW_cssValue("23px") }}>$10.00</strong>{" "}
              <del sx={{ color: "dt_content_secondary", fontSize: RAW_cssValue("18px") }}>$30.00</del>{" "}
              <strong
                sx={{
                  color: "dt_content_danger",
                  fontSize: RAW_cssValue("23px"),
                  backgroundColor: "dt_background_secondary",
                  display: "inline-block",
                }}
              >
                %66 OFF
              </strong>
              <br />
              <LabelText sx={{ color: "dt_content_success" }}>100% money back guarantee</LabelText>
            </div>
          </div>
        </div>
      ) : (
        <div sx={{ maxWidth: 800, margin: "auto", textAlign: "center", paddingInline: "dt_m" }}>
          <DisplayText level={3} sx={{ ...sxHeading }}>
            Get started with a trial.
          </DisplayText>
          <HeadlineText as="h2" sx={{ ...sxHeadingDisplay }}>
            Cancel any time. 100% money back guarantee.
          </HeadlineText>
        </div>
      )}
      <div sx={{ ...Wrapper }}>
        {Content.map((item, index) => (
          <div key={index} sx={{ ...Card }}>
            <div>
              <img src={item.number} width={80} alt="Step number" />
              <HeadlineText as="h3" sx={{ ...DojoDisplay, marginBottom: "dt_s" }}>
                {item.headline}
              </HeadlineText>
              <BodyText as="p" sx={{ ...DojoText }}>
                {item.body}
              </BodyText>
            </div>
            <img
              src={item.background}
              width={item.width}
              height={item.height}
              alt="Step illustration"
              sx={{ marginTop: "dt_xl" }}
            />
          </div>
        ))}
      </div>
      <div sx={{ ...BlobWrapper }}>
        <HeadlineText as="h2" sx={{ ...sxDisplay }}>
          Ready for more? <br />
          Enroll in weekly classes
        </HeadlineText>
        <BodyText as="p" sx={{ ...sxBodyText, maxWidth: 500 }}>
          Classes are 25 minutes long, online via Zoom, and tailored to fit your child.
        </BodyText>
        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            gap: "dt_s",
            marginBottom: "dt_xl",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BodyText sx={{ ...WhiteCard }}>
            1 class per week
            <br />
            <strong>$30/week</strong>
          </BodyText>
          <BodyText sx={{ ...WhiteCard }}>
            2 classes per week
            <br />
            <strong>$60/week</strong>
          </BodyText>
          <BodyText sx={{ ...WhiteCard }}>
            3 classes per week
            <br />
            <strong>$90/week</strong>
          </BodyText>
        </div>
        <Button data-name="try-2" onClick={redirectToBooking} sx={{ ...CTAButton }}>
          Try a class
        </Button>
      </div>
    </div>
  );
};

export default GetStarted;

const Wrapper: ThemeUIStyleObject = {
  maxWidth: RAW_cssValue("1140px"),
  margin: "auto",
  display: "flex",
  paddingInline: "dt_l",
  paddingBlock: "dt_xxl",
  gap: "dt_m",
  flexDirection: ["column", "row"],
  alignItems: "flex-start",
};

const Card: ThemeUIStyleObject = {
  backgroundColor: RAW_cssValue("#F1F3F8"),
  borderRadius: "dt_radius_s",
  paddingTop: "dt_m",
  paddingInline: "dt_xxl",
  flex: 1,
  textAlign: "center",
  "*": {
    color: RAW_cssValue("#000000 !important"),
  },
  "&:nth-of-type(1)": {
    transform: "rotate(-1deg)",
    div: {
      transform: "rotate(1deg)",
    },
    "&:before": {
      display: ["none", "none", "block"],
      content: '""',
      backgroundImage: "url('landing2023/trial-monster.svg')",
      width: 166,
      height: 156,
      position: "absolute",
      left: 0,
      top: -156,
    },
  },
  "&:nth-of-type(3)": {
    transform: "rotate(1.23deg)",
    div: {
      transform: "rotate(-1.23deg)",
    },
  },
};

const WhiteCard: ThemeUIStyleObject = {
  backgroundColor: "dt_content_light",
  borderRadius: "dt_radius_s",
  flex: 1,
  padding: "dt_m",
  color: RAW_cssValue("#000000 !important"),
  fontFamily: "'Dojo Text' !important",
  maxWidth: ["250px", "auto"],
  width: "100%",
};
const BgContainer: ThemeUIStyleObject = {
  paddingBlock: "dt_xxl",
  backgroundImage: "url('landing2023/blob.svg')",
  borderBottom: RAW_cssValue("solid 1px #F1F3F8"),
  backgroundRepeat: "no-repeat",
  backgroundPosition: ["center 110%", "center 350px"],
};

const sxBodyText: ThemeUIStyleObject = {
  color: RAW_cssValue("#000000"),
  fontSize: ["18px !important", "22px !important"],
  lineHeight: "150% !important",
  fontWeight: 500,
  letterSpacing: "-0.25%",
  marginBottom: "dt_l",
};

const sxHeading: ThemeUIStyleObject = {
  fontSize: [32, 48],
  lineHeight: "110%",
  fontFamily: "'Dojo Display' !important",
  fontFeatureSettings: "'ss04' on",
  fontWeight: 900,
  marginBottom: "dt_s",

  color: RAW_cssValue("#000000"),
};

const sxDisplay: ThemeUIStyleObject = {
  fontFamily: "'Dojo Display' !important",
  fontFeatureSettings: "'ss04' on",
  display: "block",
  color: RAW_cssValue("#000000"),
  marginBlock: "dt_l",
  position: "relative",
  "&::after": {
    display: ["none", "block"],
    position: "absolute",
    content: "''",
    width: 146,
    height: 146,
    backgroundImage: "url('landing2023/arrows1.svg')",
    top: -80,
    right: -140,
    zIndex: 10,
  },
};
const sxHeadingDisplay: ThemeUIStyleObject = {
  color: RAW_cssValue("#000000 !important"),
  fontFamily: "'Dojo Text' !important",
  fontSize: [22, 24],
  fontWeight: 500,
};
const CTAButton: ThemeUIStyleObject = {
  backgroundColor: RAW_cssValue("#1A192D"),
  position: "relative",
  ":hover": {
    backgroundColor: RAW_cssValue("#000000"),
  },
  span: {
    fontFamily: "'Dojo Display' !important",
    fontFeatureSettings: "'ss04' on",
  },
  "&::after": {
    display: "block",
    position: "absolute",
    content: "''",
    width: 99,
    height: 99,
    backgroundImage: "url('landing2023/arrows2.svg')",
    bottom: -50,
    left: -100,
    zIndex: 10,
  },
};
const BlobWrapper: ThemeUIStyleObject = {
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  maxWidth: 650,
  alignItems: "center",
  marginInline: "auto",
  marginTop: [0, RAW_cssValue("-80px")],
  paddingInline: "dt_l",
};

const DojoText: ThemeUIStyleObject = {
  color: RAW_cssValue("#000000 !important"),
  fontFamily: "'Dojo Text' !important",
};

const DojoDisplay: ThemeUIStyleObject = {
  color: RAW_cssValue("#000000 !important"),
  fontFamily: "'Dojo Display' !important",
  fontFeatureSettings: "'ss04' on",
};
