import { showNotification } from "@mantine/notifications";
import { ActionIcon, Box, Button, Check, Dropzone, Image, Modal, SimpleGrid, Text, Upload, X } from "UILibrary";
import axios from "axios";
import { useTeacherMutation } from "old/src/hooks/useTeacher";
import { toBase64, urlToFile } from "old/src/utils";
import piexif from "piexifjs";
import React, { useState } from "react";
import TeacherAPI from "src/api/teacher";
import { useSessionQuery } from "src/hooks/useSession";

interface PhotoUploadModalProps {
  onClose: () => void;
  opened: boolean;
  refetch?: () => void;
}

const PhotoUploadModal: React.FC<PhotoUploadModalProps> = ({ onClose, opened, refetch }) => {
  const [image, setImage] = useState<any>(null);
  const [imageFile, setImageFile] = useState<null | File>(null);
  const { updateTeacherProfile } = useTeacherMutation();
  const [loading, setLoading] = useState(false);
  const { sessionData } = useSessionQuery();

  const onSetImagePreview = async (file: File) => {
    try {
      const base64 = await toBase64(file);
      const test = piexif.remove(base64);
      const cleanFile = await urlToFile(test, `${sessionData?.user_details?.email}-profile-photo`);
      const url = URL.createObjectURL(cleanFile);

      // eslint-disable-next-line no-console
      console.log(cleanFile);

      setImageFile(cleanFile);
      setImage(url);
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (error) {
      const url = URL.createObjectURL(file);
      setImageFile(file);
      setImage(url);
    }
  };

  const onSaveImage = async () => {
    setLoading(true);
    try {
      const extension = imageFile?.type.split("/").pop();
      const response = await TeacherAPI.getUploadPhotoUrl(extension as string);

      if (response?.data && imageFile) {
        const formData = new FormData();
        formData.append("file", imageFile);

        const putResponse = await axios.put(response?.data?.upload_url, imageFile, {
          headers: {
            "Content-Type": "",
          },
        });

        if (putResponse?.status === 200) {
          await updateTeacherProfile.mutateAsync({ photo_url: response?.data?.object_url }, {
            onSuccess: () => {
              refetch && refetch();
              onClose();
              showNotification({
                title: "Profile updated",
                message: (
                  <Text>
                    We just updated your profile picture.
                    <br />
                    Your students will love it!
                  </Text>
                ),
                color: "teal",
                icon: <Check />,
              });
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
          } as any);
        }
      }
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (error) {
      showNotification({
        title: "Photo upload error",
        message: <Text>We just encountered an error! Please try to upload again.</Text>,
        color: "red",
        icon: <X />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      styles={{
        content: { borderRadius: "56px" },
        body: {
          position: "relative",
          padding: "52px 32px",
        },
      }}
      size={547}
      opened={opened}
      onClose={onClose}
      centered
      withCloseButton={false}
    >
      <ActionIcon onClick={onClose} sx={{ position: "absolute", right: 20, top: 0 }}>
        <X />
      </ActionIcon>

      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Text
          sx={
            {
              fontSize: "24px",
              lineHeight: "30px",
              color: "#2C2A50",
              fontWeight: 700,
            } as any
          }
          mb={16}
        >
          Upload your profile photo
        </Text>

        {image ? (
          <Box sx={{ width: "300px", height: "300px" }}>
            <Image src={image} alt="profile picture" />
          </Box>
        ) : (
          <Dropzone
            sx={{
              height: "300px",
              width: "300px",
              backgroundColor: "#EAECF5",
              border: "2px dashed #D3D7EC",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "24px",
            }}
            onDrop={(files) => {
              onSetImagePreview(files[0]);
            }}
            // eslint-disable-next-line no-console
            onReject={(files) => console.log("rejected files", files)}
            accept={{
              "image/*": [".jpeg", ".jpg", ".png"],
            }}
            multiple={false}
          >
            <Upload size={64} />
            <Text mt={16} sx={{ fontSize: "12px", lineHeight: "16px", color: "#2C2A50" } as any}>
              or drag a file in the gray box from your files
            </Text>
            <Text sx={{ fontSize: "12px", lineHeight: "16px", color: "#2C2A50" } as any}>
              You can upload image files (jpg,png) up to 5mb{" "}
            </Text>
          </Dropzone>
        )}

        {image && (
          <SimpleGrid mt={24} cols={2} spacing={16}>
            <Button disabled={loading} onClick={() => setImage(null)} variant="white">
              Change Photo
            </Button>

            <Button loading={loading} disabled={loading} onClick={onSaveImage}>
              Save photo
            </Button>
          </SimpleGrid>
        )}
      </Box>
    </Modal>
  );
};

export default PhotoUploadModal;
