import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { Button, BodyText, HeadlineText, RoundedIcon, Tooltip, theme } from "@classdojo/web/nessie";
import { UseFormReturnType, useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import startCase from "lodash/startCase";
import { useNavigate, useParams } from "react-router-dom";
import RichText from "src/components/RichText";
import { useTeacherMutation, useClassAssessmentByIdQuery, useGeneratedAssessmentQuery } from "old/src/hooks/useTeacher";
import { useState } from "react";
import { GeneratedAssessment } from "src/api/teacher/getGeneratedAssessment";
import RadioGroup from "src/components/RadioGroup";
import RadioGroupOption from "src/components/RadioGroup/RadioGroupOption";
import { useInterval } from "@classdojo/web";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { logTutorEvent } from "src/utils/log";
import { formatWithPattern, parse } from "src/utils/dates";
import { IconArrowLeft, IconCheckCircleFilled, IconFlashOn } from "@web-monorepo/dds-icons";
import { AxiosError } from "axios";

const recommendedClasses = [
  { value: 1, label: "1 class per week" },
  { value: 2, label: "2 classes per week" },
  { value: 3, label: "3 classes per week" },
];

const fieldsInfo = {
  class_overview: {
    label: "Class overview",
    generatedAssessmentField: "classOverview",
  },
  areas_of_strength: {
    label: "Areas of strength",
    generatedAssessmentField: "areasOfStrength",
  },
  areas_of_improvement: {
    label: "Areas of improvement",
    generatedAssessmentField: "areasOfImprovement",
  },
  recommended: {
    label: "Recommended activities and resources",
    generatedAssessmentField: "recommended",
  },
  work_plan: {
    label: "Work plan for the following classes",
    generatedAssessmentField: "workPlan",
  },
};

// implie the type from fieldsInfo
type FieldName = keyof typeof fieldsInfo;
const fieldsNames: FieldName[] = [
  "class_overview",
  "areas_of_strength",
  "areas_of_improvement",
  "recommended",
  "work_plan",
];

type EnrichedFieldProps = {
  form: UseFormReturnType<{
    class_overview: string;
    areas_of_strength: string;
    areas_of_improvement: string;
    recommended: string;
    work_plan: string;
    frequency: number;
  }>;
  fieldName: FieldName;
  generatedAssessment?: GeneratedAssessment;
};

const fieldValueIsEmpty = (fieldValue: string) => fieldValue === "<p><br></p>" || !fieldValue;

type AIZapButtonProps = {
  onClick: () => void;
  fieldName: string;
};
const AIZapButton = ({ onClick, fieldName }: AIZapButtonProps) => {
  useOnFirstRender(() => {
    logTutorEvent({
      eventName: "tutor.marketplace.assessment.aiZapButton.rendered",
      metadata: {
        fieldName,
      },
    });
  });

  return (
    <Button
      sx={{ position: "absolute", bottom: "18px", left: "18px" }}
      kind="secondary"
      data-name="assessment:generate-using-ai"
      onClick={() => {
        logTutorEvent({
          eventName: "tutor.marketplace.assessment.aiZapButton.click",
          metadata: {
            fieldName,
          },
        });
        onClick();
      }}
      size="s"
    >
      <IconFlashOn size="s" sx={{ color: "dt_content_tertiary" }} /> Generate using AI
    </Button>
  );
};

const EnrichedField = ({ form, fieldName, generatedAssessment }: EnrichedFieldProps) => {
  const fieldValue = form.values[fieldName];
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  // timeout to prevent the button rendering before the field is rendered
  useInterval(() => {
    if (generatedAssessment) {
      setIsButtonVisible(true);
    }
  }, 1000);

  const fillFieldAISuggestedValue = () => {
    if (!generatedAssessment) return;
    const { assessmentJson } = generatedAssessment;
    const assessmentProperty = fieldsInfo[fieldName].generatedAssessmentField as keyof typeof assessmentJson;
    const suggestedValue = assessmentJson[assessmentProperty] ?? fieldValue;
    form.setFieldValue(fieldName, suggestedValue);
  };

  return (
    <div>
      <BodyText sx={sxLabel}>{fieldsInfo[fieldName].label}</BodyText>
      <div sx={sxRichField} data-name={`assessment:${fieldName}`}>
        <RichText
          controls={[
            ["Bold", "Italic", "Underline", "Link"],
            ["BulletList", "H1", "H2", "H3"],
            ["AlignLeft", "AlignCenter", "AlignRight"],
          ]}
          {...form.getInputProps(fieldName)}
        />
      </div>
      {isButtonVisible && fieldValueIsEmpty(fieldValue) && (
        <div sx={{ position: "relative" }}>
          <AIZapButton onClick={fillFieldAISuggestedValue} fieldName={fieldName} />
        </div>
      )}
      {form.errors[fieldName] && (
        <BodyText
          sx={
            {
              fontSize: "14px",
              lineHeight: "24px",
              color: "#f03e3e",
            } as any
          }
        >
          {form.errors[fieldName]}
        </BodyText>
      )}
    </div>
  );
};

const TutorCreateAssessment = () => {
  const query = useParams();
  const push = useNavigate();
  const { classData } = useClassAssessmentByIdQuery(query?.assessmentId as string | undefined);

  const { createAssessment } = useTeacherMutation();
  const { generatedAssessment } = useGeneratedAssessmentQuery(query?.assessmentId as string | undefined);
  const [showHelpTooltip, setShowHelpTooltip] = useState(false);

  const form = useForm({
    initialValues: {
      class_overview: "",
      areas_of_strength: "",
      areas_of_improvement: "",
      recommended: "",
      work_plan: "",
      frequency: 1,
    },

    validate: {
      class_overview: (value) => (fieldValueIsEmpty(value) ? "This field is required." : null),
      areas_of_strength: (value) => (fieldValueIsEmpty(value) ? "This field is required." : null),
      areas_of_improvement: (value) => (fieldValueIsEmpty(value) ? "This field is required." : null),
      recommended: (value) => (fieldValueIsEmpty(value) ? "This field is required." : null),
      work_plan: (value) => (fieldValueIsEmpty(value) ? "This field is required." : null),
      frequency: (value) => (1 <= value && value <= 3 ? null : "Must be a value between 1 and 3."),
    },
  });

  const onSubmitAssessment = async (values: any) => {
    const payload = {
      ...values,
      date_created: new Date().toISOString(),
      id: classData?.classId,
    };

    createAssessment.mutate(payload, {
      onSuccess: () => {
        showNotification({
          title: "Assessment created!",
          message: "We have now posted the assessment of this class",
          color: "teal",
          icon: <IconCheckCircleFilled size="m" />,
        });
        push(
          generatedAssessment
            ? `/teachers/generatedAssessment/${generatedAssessment.assessmentId}/review`
            : "/teachers/calendar",
        );
      },
      onError: (error: AxiosError<{ error?: { detail?: string } }>) => {
        showNotification({
          title: "Assessment failed to create",
          message: error.message || "Server Error",
          color: "red",
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
    } as any);
  };

  if (!classData) {
    return null;
  }

  return (
    <div sx={sxGeneralWrapper}>
      <div>
        <Button sx={sxBackButton} onClick={() => push("/teachers/calendar")} data-name="back" kind="tertiary">
          <IconArrowLeft sx={sxBackIcon} size="m" /> Back to calendar
        </Button>
        <div sx={sxWrapper}>
          <div sx={sxHeadlineWrapper}>
            <div>
              <HeadlineText as="h1" sx={sxHeadline}>
                Tutoring assessment
              </HeadlineText>
              <BodyText sx={sxSubheadlineDesktop}>
                {startCase(classData?.subject ?? "")} • {startCase(classData?.grade ?? "")}
              </BodyText>
            </div>
            <div>
              <BodyText sx={sxChildName}>
                <div>{classData?.childName}</div>
                {generatedAssessment && (
                  <div
                    // eslint-disable-next-line @web-monorepo/no-jsx-role-button
                    role="button"
                    tabIndex={0}
                    sx={sxIconWrapper}
                    onClick={() => setShowHelpTooltip(!showHelpTooltip)}
                  >
                    <div sx={sxTooltipWrapper}>
                      <Tooltip
                        isOpen={showHelpTooltip}
                        placement="bottom"
                        triangleStyle={sxTooltipTriangle}
                        maxWidth="303px"
                      >
                        <div sx={sxTooltipContent}>
                          AI summary available. Click the "Generate using AI" button to get a suggested paragraph for
                          each of the sections in the assessment.{" "}
                          <a sx={sxLink} href="https://classdojo.zendesk.com/hc/en-us">
                            Learn more
                          </a>
                        </div>
                      </Tooltip>
                    </div>
                    <RoundedIcon
                      backgroundColor="dt_background_secondary"
                      icon={<IconFlashOn size="s" sx={{ color: "dt_content_tertiary" }} />}
                      size="xs"
                    />
                  </div>
                )}
              </BodyText>
              <BodyText sx={sxSubheadlineMobile}>
                {startCase(classData?.subject ?? "")} • {startCase(classData?.grade ?? "")}
              </BodyText>
              <BodyText sx={sxClassTime}>{formatWithPattern(parse(classData?.startTime), "PPp")}</BodyText>
            </div>
          </div>

          <div>
            <form onSubmit={form.onSubmit(onSubmitAssessment)}>
              <div sx={sxFields}>
                {fieldsNames.map((fieldName) => (
                  <EnrichedField
                    form={form}
                    fieldName={fieldName}
                    generatedAssessment={generatedAssessment}
                    key={fieldName}
                  />
                ))}

                <div>
                  <BodyText sx={sxLabel}>Number of classes recommended per week</BodyText>
                  <RadioGroup sx={sxRadioGroup} {...form.getInputProps("frequency")}>
                    {recommendedClasses.map(({ value, label }, index) => (
                      <div key={index} sx={sxRadioGroupOption}>
                        <RadioGroupOption value={value}>{label}</RadioGroupOption>
                      </div>
                    ))}
                  </RadioGroup>
                </div>
              </div>

              <Button
                disabled={createAssessment.isLoading}
                type="submit"
                sx={sxSubmitButton}
                data-name="teacher_calendar.postAssessmentModal.saveButton"
              >
                Save
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorCreateAssessment;

const sxGeneralWrapper: ThemeUIStyleObject = {
  marginTop: RAW_cssValue("-24px"),
  minWidth: "320px",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginTop: RAW_cssValue("-42px"),
  },
};

const sxBackButton: ThemeUIStyleObject = {
  marginBottom: RAW_cssValue("16px"),
  marginLeft: RAW_cssValue("8px"),

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginBottom: "dt_s",
  },
};

const sxWrapper: ThemeUIStyleObject = {
  borderColor: "dt_border_divider",
  borderWidth: "2px",
  borderRadius: "dt_radius_m",
  borderStyle: "solid",
  maxWidth: "960px",
  margin: "auto",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    borderWidth: "0px",
  },
};

const sxHeadline: ThemeUIStyleObject = {
  fontSize: "28px",
  fontWeight: 800,
  lineHeight: "36px",
  letterSpacing: "-0.2px",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginBottom: "dt_m",
  },
};

const sxHeadlineWrapper: ThemeUIStyleObject = {
  paddingTop: RAW_cssValue("16px"),
  paddingBottom: RAW_cssValue("16px"),
  paddingLeft: "dt_m",
  paddingRight: "dt_m",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "dt_divider",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    borderWidth: "0px",
    flexDirection: "column",
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: RAW_cssValue("6px"),
    paddingRight: RAW_cssValue("6px"),
  },
};

const sxSubheadline: ThemeUIStyleObject = {
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "18px",
  color: "dt_content_secondary",
};

const sxSubheadlineDesktop: ThemeUIStyleObject = {
  ...sxSubheadline,
  display: "none",

  [`@media (min-width: ${theme.breakpoints.s})`]: {
    display: "block",
  },
};

const sxSubheadlineMobile: ThemeUIStyleObject = {
  ...sxSubheadline,
  display: "block",

  [`@media (min-width: ${theme.breakpoints.s})`]: {
    display: "none",
  },
};

const sxChildName: ThemeUIStyleObject = {
  fontSize: "23px",
  fontWeight: 800,
  lineHeight: "30px",
  letterSpacing: "-0.1px",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    justifyContent: "start",
  },
};

const sxClassTime: ThemeUIStyleObject = {
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "18px",
  color: "dt_content_secondary",
};

const sxLabel: ThemeUIStyleObject = {
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "24px",
  color: "dt_content_primary",
  marginBottom: "dt_xs",
};

const sxIconWrapper: ThemeUIStyleObject = {
  width: "xs",
  height: "s",
  marginLeft: "s",
  cursor: "pointer",
  position: "relative",
};

const sxFields: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_l",
  padding: "dt_l",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    paddingTop: "dt_s",
    paddingBottom: "dt_s",
    paddingLeft: RAW_cssValue("6px"),
    paddingRight: RAW_cssValue("6px"),
  },
};

const sxRichField: ThemeUIStyleObject = {
  minHeight: "170px",
  borderRadius: "dt_radius_m",

  ".ql-toolbar": {
    borderTopRightRadius: "dt_radius_m",
    borderTopLeftRadius: "dt_radius_m",
  },

  [`@media (max-width: 409px)`]: {
    minHeight: "215px",
  },
};

const sxSubmitButton: ThemeUIStyleObject = {
  width: "257px",
  margin: "auto",
  marginTop: "dt_xl",
  marginBottom: "dt_l",
};

const sxTooltipTriangle: ThemeUIStyleObject = {
  left: "250px",
  height: "20px",
  top: "2px",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    left: "100px",
  },
};

const sxTooltipContent: ThemeUIStyleObject = {
  fontSize: "15px",
  fontWeight: "600",
  lineHeight: "18px",
};

const sxTooltipWrapper: ThemeUIStyleObject = {
  position: "absolute",
  width: "303px",
  left: "-236px",
  top: "40px",
  zIndex: "100",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    left: "-86px",
  },
};

const sxRadioGroup: ThemeUIStyleObject = {
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "1fr",
  gap: "dt_s",
  width: "100%",
  maxWidth: "738px",

  [`@media (max-width: ${theme.breakpoints.dt_viewport_l})`]: {
    display: "flex",
    flexDirection: "column",
  },
};

const sxRadioGroupOption: ThemeUIStyleObject = {
  maxWidth: "240px",
  [`@media (max-width: ${theme.breakpoints.dt_viewport_l})`]: {
    width: "100%",
    maxWidth: "100%",
  },
};

const sxLink: ThemeUIStyleObject = {
  color: "dt_content_accent",
};

const sxBackIcon: ThemeUIStyleObject = {
  fontSize: "14px",
};
