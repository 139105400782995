import { BodyText, DisplayText, HeadlineText, Tooltip, theme } from "@classdojo/web/nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { Avatar, Button, Check, Edit, Image, Link, Loader, SimpleGrid } from "UILibrary";
import { CheckCircleFilledIcon, BookIcon, ListIcon } from "@classdojo/web/nessie/components/iconsV2/output";
import startCase from "lodash/startCase";
import uniq from "lodash/uniq";
import { usePrivateTeacherProfileQuery, useTeacherMutation } from "old/src/hooks/useTeacher";
import { useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import { useSessionQuery } from "src/hooks/useSession";
import { logTutorEvent } from "src/utils/log";
import EditingProfile from "./EditingProfile";
import { FormValues } from "./FormValues";
import { IconHelpCircleFilled } from "@web-monorepo/dds-icons";
import { UnstyledButton } from "@classdojo/web";

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { teacher, isLoading, refetch } = usePrivateTeacherProfileQuery();
  const { updateTeacherProfile } = useTeacherMutation();
  const [mathEnabled, setMathEnabled] = useState(false);
  const [readingEnabled, setReadingEnabled] = useState(false);
  const [writingEnabled, setWritingEnabled] = useState(false);
  const { sessionData } = useSessionQuery();
  const [showShareLinkTooltip, setShowShareLinkTooltip] = useState(false);

  useEffect(() => {
    if (teacher) {
      form.setValues({
        first_name: teacher.first_name,
        last_name: teacher.last_name,
        display_name: teacher.display_name ?? "",
        country: teacher.country,
        teaching_experience_description: teacher.teaching_experience_description,
        math: teacher.tutoring_information?.math ?? [],
        reading: teacher.tutoring_information?.reading ?? [],
        writing: teacher.tutoring_information?.writing ?? [],
        specializations: teacher.specializations ?? {},
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacher]);

  const form = useForm<FormValues>({
    initialValues: {
      first_name: "",
      last_name: "",
      display_name: "",
      country: null as string | null,
      math: [] as string[],
      reading: [] as string[],
      writing: [] as string[],
      specializations: {},
      teaching_experience_description: "",
    },

    validate: {
      first_name: (value) => (value ? null : "First name is required"),
      last_name: (value) => (value ? null : "Last name is required"),
      math: (value, values) =>
        !(value?.length > 0 || values.reading?.length > 0 || values.writing?.length > 0) ? "Error" : null,
      reading: (value, values) =>
        !(value?.length > 0 || values.math?.length > 0 || values.writing?.length > 0) ? "Error" : null,
      writing: (value, values) =>
        !(value?.length > 0 || values.math?.length > 0 || values.reading?.length > 0) ? "Error" : null,
    },
  });

  const teacherSubjects = useMemo(() => {
    if (teacher?.tutoring_information) {
      const subjects = Object.keys(teacher?.tutoring_information)
        .filter((value: keyof typeof teacher.tutoring_information) => teacher.tutoring_information[value]?.length > 0)
        .map((value) => startCase(value))
        .join(", ");

      return subjects;
    }

    return null;
  }, [teacher]);

  const teacherGrades = useMemo(() => {
    if (teacher?.tutoring_information) {
      const grades = Object.keys(teacher.tutoring_information)
        .map((value: keyof typeof teacher.tutoring_information) => teacher.tutoring_information[value])
        .flat();

      return uniq(grades)
        .map((value) => startCase(value))
        .join(", ");
    }

    return null;
  }, [teacher]);

  useEffect(() => {
    if (form.values.math?.length > 0) {
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setMathEnabled(true);
    }

    if (form.values.reading?.length > 0) {
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setReadingEnabled(true);
    }

    if (form.values.writing?.length > 0) {
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setWritingEnabled(true);
    }
  }, [form.values.math, form.values.reading, form.values.writing]);

  const onUpdateTeacherProfile = async (values: any) => {
    const body = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      country: values?.country,
      teaching_experience_description: values?.teaching_experience_description,
      tutoring_information: {
        math: values?.math,
        reading: values?.reading,
        writing: values?.writing,
      },
      display_name: values?.display_name,
      specializations: values?.specializations,
    };

    await updateTeacherProfile.mutateAsync(body, {
      onSuccess: () => {
        refetch();
        setIsEditing(false);
        showNotification({
          title: "Profile updated",
          message: (
            <BodyText level={2}>
              We just updated your profile.
              <br />
              Your students will love it!
            </BodyText>
          ),
          color: "teal",
          icon: <Check />,
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
    } as any);
  };

  const fallbackCopyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    showNotification({
      title: "Profile page link copied",
      message: (
        <BodyText level={2}>
          Profile page link copied to your clipboard.
          <br />
          Share it with families!
        </BodyText>
      ),
      color: "teal",
      icon: <Check />,
    });
  };

  const copyProfileLink = () => {
    logTutorEvent("tutor.marketplace.directbooking.copy_link");
    const url = `${window.location.origin}/direct-booking-subscription/tutor-introduction/${sessionData?.user_details.uid}`;

    if (navigator.share) {
      navigator
        .share({
          title: `Book classes with ${sessionData?.user_details.first_name} ${sessionData?.user_details.last_name
            .substring(0, 1)
            .toUpperCase()}`,
          text: `Use this link to book classes with ${
            sessionData?.user_details.first_name
          } ${sessionData?.user_details.last_name.substring(0, 1).toUpperCase()}`,
          url,
        })
        .catch(() => {
          fallbackCopyToClipboard(url);
        });
    } else {
      // Fallback to clipboard copy if Web Share API is not supported
      fallbackCopyToClipboard(url);
    }
  };

  const selectedSpecializations = useMemo(() => {
    return Object.keys(form.values.specializations).filter((key) => form.values.specializations[key]);
  }, [form.values.specializations]);

  if (isEditing) {
    return (
      <EditingProfile
        close={() => setIsEditing(false)}
        loading={updateTeacherProfile.isLoading}
        form={form}
        mathEnabled={mathEnabled}
        readingEnabled={readingEnabled}
        writingEnabled={writingEnabled}
        onUpdateTeacherProfile={onUpdateTeacherProfile}
        setMathEnabled={setMathEnabled}
        setReadingEnabled={setReadingEnabled}
        setWritingEnabled={setWritingEnabled}
      />
    );
  }

  return (
    <>
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "dt_m",
          gap: "dt_m",
          [`@media (max-width: ${theme.breakpoints.s})`]: {
            flexDirection: "column",
            textAlign: "center",
            alignItems: "flex-start",
          },
        }}
      >
        <div sx={{ flexGrow: 1 }}>
          <HeadlineText as="h2">Profile</HeadlineText>
        </div>
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "dt_m",
          }}
        >
          <Button variant="outline" size="md" leftIcon={<Link />} onClick={() => copyProfileLink()} type="submit">
            Copy your Profile page link
          </Button>
          <div sx={sxHelpIconWrapper}>
            <div sx={sxTooltipWrapper}>
              <Tooltip isOpen={showShareLinkTooltip} placement="bottom" triangleStyle={sxTooltipTriangle}>
                <div sx={sxTooltipContent}>
                  Send this link to families so they can book classes with you directly.
                  <br />
                  <a
                    sx={sxLink}
                    target="_blank"
                    href="https://docs.google.com/document/d/e/2PACX-1vSjR1FncZiUhpJAtWdpBSBlQLOcPwR-fAaoIgFtbXGbtbSfrxcGaEcQGr-coj7CXUMT4C3LBEBDpaev/pub"
                  >
                    Learn more
                  </a>
                </div>
              </Tooltip>
            </div>
            <UnstyledButton
              data-name="show-share-link-tooltip"
              aria-label="Show share link tooltip"
              onClick={() => setShowShareLinkTooltip(!showShareLinkTooltip)}
            >
              <IconHelpCircleFilled size="m" sx={{ cursor: "pointer", color: "dt_content_accent" }} />
            </UnstyledButton>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "60vh",
          }}
        >
          <Loader size="xl" />
        </div>
      ) : (
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: RAW_cssValue("0px 2px 0px rgba(45, 64, 150, 0.06)"),
            border: "dt_card",
            borderRadius: "dt_radius_s",
            background: "#FFF",
            overflow: "hidden",
            padding: "dt_xl",
            [`@media (max-width: ${theme.breakpoints.m})`]: {
              padding: "dt_m",
            },
          }}
        >
          <div
            sx={{
              paddingTop: "dt_m",
              paddingBottom: "dt_m",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              [`@media (max-width: ${theme.breakpoints.m})`]: {
                padding: 0,
                display: "block",
              },
            }}
          >
            <div
              sx={{
                [`@media (max-width: ${theme.breakpoints.m})`]: {
                  paddingBottom: "dt_s",
                },
              }}
            >
              {teacher?.photo_url ? (
                <Image
                  styles={{
                    image: {
                      background: "#F7F8FF",
                    },
                  }}
                  width={96}
                  height={96}
                  radius={24}
                  mr={16}
                  src={teacher?.photo_url}
                  alt="ClassDojo teacher profile"
                />
              ) : (
                <Avatar size={96} radius={24} mr={16} />
              )}
            </div>
            <div sx={{ flexGrow: 1 }}>
              {teacher?.display_name ? (
                <>
                  <HeadlineText as="h1" level={1}>
                    {teacher?.display_name}
                  </HeadlineText>
                  <BodyText level={2} sx={{ marginTop: "xs" }}>
                    {teacher?.first_name} {teacher?.last_name}
                  </BodyText>
                </>
              ) : (
                <DisplayText level={3}>
                  {teacher?.first_name} {teacher?.last_name}
                </DisplayText>
              )}

              <BodyText level={2} sx={{ marginTop: "xs", marginBottom: "xxs" }}>
                {teacher?.time_zone} • {teacher?.teaching_experience_years} years of teaching experience
              </BodyText>
            </div>
            <div>
              <Button
                rightIcon={<Edit />}
                onClick={() => setIsEditing(true)}
                variant="light"
                sx={{
                  height: "52px",
                  paddingLeft: "dt_m",
                  paddingRight: "dt_m",
                  paddingTop: "dt_s",
                  paddingBottom: "dt_s",
                  marginTop: "dt_l",
                  marginBottom: "dt_l",
                }}
              >
                Edit profile
              </Button>
            </div>
          </div>

          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "dt_xl",
              paddingBottom: "dt_xl",
              borderTop: "dt_divider",
              borderBottom: "dt_divider",
              gap: "dt_m",

              [`@media (max-width: ${theme.breakpoints.m})`]: {
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <div
              sx={{
                display: "flex",
                alignItems: "flex-start",
                [`@media (max-width: ${theme.breakpoints.m})`]: {
                  flexDirection: "column",
                },
                gap: "dt_l",
                alignSelf: "stretch",
              }}
            >
              <div sx={{ display: "flex", gap: "dt_xs", alignItems: "center" }}>
                <ListIcon />
                <BodyText level={2}>{teacherGrades}</BodyText>
              </div>
              <div sx={{ display: "flex", gap: "dt_xs", alignItems: "center" }}>
                <BookIcon />
                <BodyText level={2}>{teacherSubjects}</BodyText>
              </div>
            </div>{" "}
            {selectedSpecializations.length > 0 && (
              <div sx={{ display: "flex", gap: "dt_xs", alignItems: "center" }}>
                <CheckCircleFilledIcon />
                <BodyText level={2}>Specializations: {selectedSpecializations.join(", ")}</BodyText>
              </div>
            )}
          </div>

          <SimpleGrid
            sx={{
              paddingTop: "dt_xl",
              paddingBottom: "dt_xl",
            }}
          >
            <BodyText level={2}>{teacher?.teaching_experience_description}</BodyText>
            {teacher?.video_url != null && (
              <div sx={{ background: "#F7FCFF", height: "225px" }}>
                <ReactPlayer
                  className="react-player fixed-bottom"
                  url={teacher.video_url}
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            )}
          </SimpleGrid>
        </div>
      )}
    </>
  );
};

export default Profile;

const sxHelpIconWrapper: ThemeUIStyleObject = { marginTop: "dt_xs", position: "relative" };
const sxTooltipTriangle: ThemeUIStyleObject = {
  left: "250px",
  height: "20px",
  top: "2px",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    left: "100px",
  },
};

const sxTooltipContent: ThemeUIStyleObject = {
  fontSize: "15px",
  fontWeight: "600",
  lineHeight: "18px",
};

const sxTooltipWrapper: ThemeUIStyleObject = {
  position: "absolute",
  width: "303px",
  left: "-236px",
  top: "40px",
  zIndex: "100",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    left: "-86px",
  },
};

const sxLink: ThemeUIStyleObject = {
  color: "dt_content_accent",
};
