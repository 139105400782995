import { theme } from "@classdojo/web/nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { ReactNode, useLayoutEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { sxWrapperBackground } from "src/pageComponents/NewSingleClass/SharedStyles";
import Header from "./Header";
import Footer from "old/src/components/Footer";
import WaveBg from "old/src/components/WaveBg";
import Modals from "src/components/Modals";
import { HEADER_HEIGHT } from "src/constants";
import React from "react";

type LayoutPortalContextType = {
  belowHeaderRef?: React.RefObject<HTMLDivElement>;
};
export const LayoutPortalContext = React.createContext<LayoutPortalContextType>({});

const useScrollHashIntoView = () => {
  const { hash } = useLocation();

  useLayoutEffect(() => {
    const id = hash.substring(1);
    document.getElementById(id)?.scrollIntoView();
  }, [hash]);
};

const useScrollToTopOnLoad = (scrollToTop: boolean) => {
  // Since many routes return this component, React doesn't see the re-render as a new instance.
  // We need to check the pathname to address route changes if we want this to run on each page load.
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [scrollToTop, pathname]);
};

type LayoutProps = {
  children: ReactNode;
  className?: string;
  footer?: boolean;
  header?: boolean;
  layout: "centered" | "padded" | "default";
  wave?: boolean;
  scrollToTop?: boolean;
  mobileAccentBackground?: boolean;
  mainClassName?: ThemeUIStyleObject;
  sxContentStyle?: ThemeUIStyleObject;
};

const Layout = ({
  children,
  className,
  layout,
  footer = true,
  header = layout !== "centered",
  wave = layout === "centered",
  scrollToTop = false,
  mobileAccentBackground = false,
  mainClassName,
  sxContentStyle,
}: LayoutProps) => {
  useScrollHashIntoView();
  useScrollToTopOnLoad(scrollToTop);

  const centered = layout === "centered";
  const padded = layout === "padded";

  const belowHeaderRef = useRef<HTMLDivElement>(null);
  const portalContextValue: LayoutPortalContextType = useMemo(() => ({ belowHeaderRef }), []);

  return (
    <LayoutPortalContext.Provider value={portalContextValue}>
      <div sx={{ ...sxMain, ...mainClassName }} data-header={header}>
        {header && <Header />}
        <div ref={belowHeaderRef} />
        <div
          sx={{ ...sxContent, ...sxContentStyle, ...(mobileAccentBackground && sxWrapperBackground) }}
          className={className}
          data-centered={centered}
          data-padded={padded}
        >
          {children}
        </div>
        {footer && <Footer includeLinks={!centered} sx={{ zIndex: wave ? 1 : undefined }} />}
        {wave && <WaveBg />}
      </div>
      <Modals />
    </LayoutPortalContext.Provider>
  );
};

export default Layout;

const sxMain: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  minHeight: "100vh",

  "&[data-header=true][data-sticky=true]": {
    paddingTop: RAW_cssValue(HEADER_HEIGHT),
  },
};

const sxContent: ThemeUIStyleObject = {
  flexGrow: 1,
  zIndex: 1,

  '&[data-padded="true"]': {
    width: "100vw",
    maxWidth: theme.breakpoints.xl,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "dt_xxl",
    paddingBottom: "dt_xxl",
    paddingLeft: "dt_xl",
    paddingRight: "dt_xl",

    [`@media (max-width: ${theme.breakpoints.m})`]: {
      paddingLeft: "dt_s",
      paddingRight: "dt_s",
    },
  },

  "&[data-centered=true]": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "dt_xl",
    paddingRight: "dt_xl",
    maxWidth: theme.breakpoints.xl,
    marginLeft: "auto",
    marginRight: "auto",

    [`@media (max-width: ${theme.breakpoints.m})`]: {
      paddingLeft: "dt_s",
      paddingRight: "dt_s",
    },
  },
};
