import { useNavigate } from "react-router-dom";
import { Button, Box, Modal, Text } from "UILibrary";
import { useClassByIdQuery, useParentMutation } from "old/src/hooks/useParent";
import { showErrorNotification } from "old/src/utils";
import { logTutorEvent } from "src/utils/log";
import { showSuccessNotification } from "src/utils/notification";
import { useModalStore } from "src/components/Modals";
import { BodyText, HeadlineText } from "@classdojo/web/nessie";
import { AxiosError } from "axios";
import queryClient from "src/queryClient";
import { format, parse, addMinutes, differenceInMinutes } from "src/utils/dates";

type CancelEnrollmentModalProps = {
  enrollmentId: string;
  fromReschedule?: boolean;
};

const refundedText =
  "You will be refunded for this class because you are canceling more than 4 hours before it starts.";
const notRefundedText =
  "You will not be refunded for this class because you are canceling within 4 hours of the start time.";

const CancelEnrollmentModal = ({ enrollmentId, fromReschedule }: CancelEnrollmentModalProps) => {
  const { classData } = useClassByIdQuery(enrollmentId);
  const push = useNavigate();
  const { cancelClass, cancelSubscriptionSession } = useParentMutation();
  const hideModal = useModalStore((store) => store.hideModal);

  const cancel = async () => {
    if (classData?.subscriptionId) {
      cancelSubscriptionSession.mutate(
        { sessionId: classData.session.sessionId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getParentSessions"] });
            hideModal();
            push("/parents/classes");
            logTutorEvent("parent.marketplace.sub_class_cancel.success");
            showSuccessNotification("Class canceled");
          },
          onError: (error: AxiosError<{ error: { code: string; detail: string } }>) => {
            const errorData = error.response?.data;
            showErrorNotification("Error canceling class", errorData?.error.detail ? errorData?.error.detail : "");
          },
        },
      );
    } else {
      cancelClass.mutate(
        { uid: enrollmentId.slice(0, 24) },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getParentSessions"] });
            hideModal();
            push("/parents/classes");
            logTutorEvent("parent.marketplace.trial_class_cancel.success");
            showSuccessNotification("Class canceled");
          },
          onError: (error: AxiosError<{ error: { code: string; detail: string } }>) => {
            const errorData = error.response?.data;
            showErrorNotification("Error canceling class", errorData?.error.detail ? errorData?.error.detail : "");
          },
        },
      );
    }
  };

  return (
    <Modal
      styles={{
        content: { borderRadius: "56px" },
        body: {
          padding: "12px 12px 8px",
        },
      }}
      size={500}
      opened={enrollmentId != null}
      onClose={hideModal}
      withCloseButton={false}
      centered
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "dt_s",
        }}
      >
        <Box>
          <HeadlineText as="h2" level={2} textAlign="center">
            {fromReschedule ? "Your class is too soon" : "Are you sure?"}
          </HeadlineText>
          <BodyText level={2} textAlign="center">
            {fromReschedule
              ? "As the class is less than for hours from now, you can only cancel the class. Reschedules can only be done more than four hours before the class."
              : "This action cannot be undone."}
          </BodyText>
        </Box>
        <Box
          sx={{
            backgroundColor: "dt_background_secondary",
            borderRadius: "dt_radius_s",
            display: "flex",
            flexDirection: "column",
            padding: "dt_s",
          }}
        >
          <Text weight="bold">Canceling class:</Text>
          <Text>
            {classData
              ? `${format(parse(classData.startTime), {
                  weekday: "short",
                  month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })} - ${format(addMinutes(parse(classData.startTime), 25), { hour: "numeric", minute: "2-digit" })}`
              : ""}
          </Text>
        </Box>
        <Box>
          <BodyText level={2}>
            {/* Give a 5-minute buffer so user has time to cancel */}
            {classData
              ? differenceInMinutes(parse(classData.startTime), new Date()) > 245
                ? refundedText
                : notRefundedText
              : ""}
          </BodyText>
        </Box>
        <Box>
          <Button
            disabled={cancelClass.isLoading || cancelSubscriptionSession.isLoading}
            onClick={cancel}
            mb={12}
            sx={{ width: "100%" }}
            type="submit"
          >
            Yes, cancel class
          </Button>
          <Button onClick={hideModal} sx={{ width: "100%" } as any} type="submit" variant="white">
            Go back
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CancelEnrollmentModal;
