import { useQuery } from "@tanstack/react-query";
import ParentAPI from "src/api/parent";
import { Subscription } from "src/api/parent/getNextCycleDate";
import TeacherAPI from "src/api/teacher";
import useMutation from "src/hooks/useMutation";
import { useSessionQuery } from "src/hooks/useSession";
import { handleError } from "src/queryClient";

const COPPA_MARKETPLACE_API_ERROR_STR = "user COPPA consent is needed.";
const COPPA_ERROR_CODE = "ERR_COPPA_CONSENT_REQUIRED";

const useBookClass = () => {
  return useMutation(ParentAPI.bookClass);
};

const useUpdateClass = () => {
  return useMutation(ParentAPI.updateClass);
};

const useReviewClass = () => {
  return useMutation(ParentAPI.reviewClass, {
    onError: handleError(false, {
      title: "Review submission failed",
    }),
  });
};

const useSubscriptionStartDate = () => {
  return useMutation(ParentAPI.postSubscriptionStartDate);
};

const useBookSubscription = () => {
  return useMutation(ParentAPI.bookSubscription);
};

const useRescheduleClass = () => {
  return useMutation(ParentAPI.rescheduleClass);
};

const useCancelClass = () => {
  return useMutation(ParentAPI.cancelClass);
};

const useUpdateSubscription = () => {
  return useMutation(ParentAPI.rescheduleSubscription);
};

const useCancelSubscriptionSession = () => {
  return useMutation(ParentAPI.cancelSubscriptionSession);
};

const useRescheduleSubscriptionSession = () => {
  return useMutation(ParentAPI.rescheduleSubscriptionSession);
};

const useCOPPAConsent = () => {
  return useMutation(ParentAPI.postCOPPAConsent);
};

const useCOPPAConsentFromToken = () => {
  return useMutation(ParentAPI.postCOPPAConsentFromToken);
};

export const checkIsCoppaError = (error: any): boolean => {
  const marketplaceApiErrorStr = error?.response?.data?.error?.detail?.error;
  const coppaErrorCode = error?.response?.data?.error?.code;

  return marketplaceApiErrorStr == COPPA_MARKETPLACE_API_ERROR_STR || coppaErrorCode == COPPA_ERROR_CODE;
};

export const useStripeSecret = (id?: string, promoCode?: string) => {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["clientSecret", promoCode, id], () => ParentAPI.getStripeSecret(id!, promoCode), {
    cacheTime: 0,
    enabled: id != null && sessionData != null,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    clientSecret: query.data?.data,
  };
};

export const useStripePaymentMethods = () => {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["paymentMethods"], () => ParentAPI.getStripePaymentMethods(), {
    cacheTime: 0,
    enabled: sessionData != null,
    keepPreviousData: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    paymentMethods: query.data?.data,
  };
};

export const usePublicTeacherProfileQuery = (id?: string | null) => {
  const query = useQuery(["publicTeacherProfile", id], () => TeacherAPI.getTeacherPublicProfile(id!), {
    enabled: id != null,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    teacher: query.data?.data,
  };
};

export const useClassesQuery = (params: Parameters<typeof ParentAPI.getClasses>[0]) => {
  const { sessionData } = useSessionQuery();

  const query = useQuery(["classes", params, sessionData], () => ParentAPI.getClasses(params), {
    enabled: sessionData != null,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    isFetching: query.isFetching,
    refetch: query.refetch,
    classes: query.data?.data,
    isCOPPAError: checkIsCoppaError(query.error),
  };
};

export const usePricesQuery = () => {
  const { sessionData } = useSessionQuery();

  const query = useQuery(["prices"], () => ParentAPI.getPrices(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: sessionData != null,
  });

  return {
    ...query,
    prices: query.data?.data,
  };
};

export const useClassByIdQuery = (id?: string) => {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["assessment", id, sessionData], () => ParentAPI.getClassById(id!), {
    enabled: id != null && sessionData != null,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const isCOPPAError = checkIsCoppaError(query.error);

  return {
    ...query,
    classData: query.data?.data,
    isCOPPAError,
  };
};

export const useClassMeetingQuery = (token?: string) => {
  const { data, error, isFetching, refetch } = useQuery(
    ["meeting", { token }],
    () => ParentAPI.getStudentMeeting(token!),
    {
      enabled: token != null,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const isCOPPAError = checkIsCoppaError(error);

  return {
    isFetching,
    refetch,
    meeting: data?.data,
    isCOPPAError,
  };
};

export const useSubscriptionNextCycle = (subscriptionId?: number | null, subscription?: Subscription | null) => {
  const { data } = useQuery(
    ["subscriptionNextCycle", { subscriptionId, subscription }],
    () => ParentAPI.getNextCycleDate({ subscriptionId: subscriptionId!, ...subscription }),
    {
      enabled: subscriptionId != null && subscription != null,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  return {
    nextCycle: data?.data,
  };
};

export const useParentMutation = () => {
  const bookClass = useBookClass();
  const bookSubscription = useBookSubscription();
  const updateClass = useUpdateClass();
  const reviewClass = useReviewClass();
  const subscriptionStartDate = useSubscriptionStartDate();
  const rescheduleClass = useRescheduleClass();
  const cancelClass = useCancelClass();
  const updateSubscription = useUpdateSubscription();
  const cancelSubscriptionSession = useCancelSubscriptionSession();
  const rescheduleSubscriptionSession = useRescheduleSubscriptionSession();

  return {
    bookClass,
    bookSubscription,
    cancelClass,
    updateSubscription,
    rescheduleClass,
    reviewClass,
    subscriptionStartDate,
    updateClass,
    cancelSubscriptionSession,
    rescheduleSubscriptionSession,
  };
};

export const useCOPPAConsentMutation = () => {
  const acceptCOPPAConsent = useCOPPAConsent();
  const acceptCOPPAConsentFromToken = useCOPPAConsentFromToken();
  return {
    acceptCOPPAConsent,
    acceptCOPPAConsentFromToken,
  };
};
