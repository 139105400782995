import { components } from "@classdojo/ts-api-types/api";
import bookClass from "./bookClass";
import bookSubscription from "./bookSubscription";
import cancelClass from "./cancelClass";
import getClassById from "./getClassById";
import getClasses from "./getClasses";
import getNextCycleDate from "./getNextCycleDate";
import getPrices from "./getPrices";
import getStripePaymentMethods from "./getStripePaymentMethods";
import getStripeSecret from "./getStripeSecret";
import getStudentMeeting from "./getStudentMeeting";
import postCOPPAConsent from "./postCOPPAConsent";
import postCOPPAConsentFromToken from "./postCOPPAConsentFromToken";
import postSubscriptionStartDate from "./postSubscriptionStartDate";
import rescheduleClass from "./rescheduleClass";
import reviewClass from "./reviewClass";
import updateClass from "./updateClass";
import rescheduleSubscription from "src/api/parent/rescheduleSubscription";
import consentSubscriptionSharing from "src/api/parent/consentSubscriptionSharing";
import cancelSubscriptionSession from "src/api/parent/cancelSubscriptionSession";
import rescheduleSubscriptionSession from "src/api/parent/rescheduleSubscriptionSession";

export type Grade = components["schemas"]["TutorGrades"];
export type Subject = components["schemas"]["TutorSubjects"];

export default {
  bookClass,
  bookSubscription,
  cancelClass,
  getClassById,
  getClasses,
  getNextCycleDate,
  getPrices,
  getStripePaymentMethods,
  getStripeSecret,
  getStudentMeeting,
  postCOPPAConsent,
  postCOPPAConsentFromToken,
  postSubscriptionStartDate,
  rescheduleSubscription,
  rescheduleClass,
  reviewClass,
  updateClass,
  consentSubscriptionSharing,
  cancelSubscriptionSession,
  rescheduleSubscriptionSession,
};
