/* eslint-disable @web-monorepo/no-nessie-typography */
import { ActionIcon, Modal, Box, Text, Button, SimpleGrid, Progress, Dropzone, Check, Upload, X } from "UILibrary";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import TeacherAPI from "src/api/teacher";
import axios from "axios";
import { useTeacherMutation } from "old/src/hooks/useTeacher";

interface VideoUploadModalProps {
  onClose: () => void;
  opened: boolean;
  refetch?: () => void;
}

const VideoUploadModal: React.FC<VideoUploadModalProps> = ({ onClose, opened, refetch }) => {
  const [video, setVideo] = useState<any>(null);
  const [videoFile, setvideoFile] = useState<null | File>(null);
  const { updateTeacherProfile } = useTeacherMutation();
  const [loading, setLoading] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);

  const onsetVideoPreview = (file: File) => {
    const url = URL.createObjectURL(file);
    setvideoFile(file);
    setVideo(url);
  };

  const onSaveVideo = async () => {
    setLoading(true);
    try {
      const response = await TeacherAPI.getUploadVideoUrl();

      if (response?.data && videoFile) {
        const formData = new FormData();
        formData.append("file", videoFile);

        const putResponse = await axios.put(response?.data?.upload_url, videoFile, {
          headers: {
            "Content-Type": "",
          },
          onUploadProgress: (progressEvent: any) => {
            const percentComplete = progressEvent.loaded / progressEvent.total;
            setVideoProgress(percentComplete * 100);
          },
        });

        if (putResponse?.status === 200) {
          await updateTeacherProfile.mutateAsync({ video_url: response?.data?.object_url }, {
            onSuccess: () => {
              refetch && refetch();
              onClose();
              showNotification({
                title: "Profile updated",
                message: (
                  <Text>
                    We just updated your profile picture.
                    <br />
                    Your students will love it!
                  </Text>
                ),
                color: "teal",
                icon: <Check />,
              });
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
          } as any);
        }
      }
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (error) {
      showNotification({
        title: "Video upload error",
        message: <Text>We just encountered an error! Please try to upload again.</Text>,
        color: "red",
        icon: <X />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      styles={{
        content: { borderRadius: "56px" },
        body: {
          position: "relative",
          padding: "52px 32px",
          paddingTop: "52px",
        },
      }}
      size={797}
      opened={opened}
      onClose={onClose}
      centered
      withCloseButton={false}
    >
      <ActionIcon onClick={onClose} sx={{ position: "absolute", right: 20, top: 0 }}>
        <X />
      </ActionIcon>

      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Text
          sx={
            {
              fontSize: "24px",
              lineHeight: "30px",
              color: "#2C2A50",
              fontWeight: 700,
            } as any
          }
          mb={16}
        >
          Upload your profile video
        </Text>

        {video ? (
          <Box sx={{ width: "533px", height: "300px" }}>
            <video sx={{ height: "100%", width: "100%" }}>
              <source src={video} />
            </video>

            {loading && <Progress value={videoProgress} />}
          </Box>
        ) : (
          <Dropzone
            sx={{
              height: "300px",
              width: "540px",
              backgroundColor: "#EAECF5",
              border: "2px dashed #D3D7EC",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "24px",
            }}
            onDrop={(files) => {
              onsetVideoPreview(files[0]);
            }}
            // eslint-disable-next-line no-console
            onReject={(files) => console.log("rejected files", files)}
            accept={["video/mp4"]}
          >
            <Upload size={64} />
            <Text mt={16} sx={{ fontSize: "12px", lineHeight: "16px", color: "#2C2A50" } as any}>
              or drag a file in the gray box from your files
            </Text>
            <Text sx={{ fontSize: "12px", lineHeight: "16px", color: "#2C2A50" } as any}>
              You can upload image files (jpg,png) up to 5mb{" "}
            </Text>
          </Dropzone>
        )}

        {video && (
          <SimpleGrid mt={40} cols={2} spacing={16}>
            <Button disabled={loading} onClick={() => setVideo(null)} variant="white">
              Change Video
            </Button>

            <Button loading={loading} disabled={loading} onClick={onSaveVideo}>
              Save Video
            </Button>
          </SimpleGrid>
        )}
      </Box>
    </Modal>
  );
};

export default VideoUploadModal;
