import { ActionIcon, Loader, ArrowLeft } from "UILibrary";
import startCase from "lodash/startCase";
import uniq from "lodash/uniq";
import { useMemo } from "react";
import { usePublicTeacherProfileQuery } from "old/src/hooks/useParent";
import { Star } from "tabler-icons-react";
import type { Subject } from "src/api/parent";
import { Image } from "@mantine/core";
import { useModalStore } from "src/components/Modals";
import ModalWrapper from "src/components/Modals/ModalWrapper";
import { theme } from "@classdojo/web/nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";

type TutorModalProps = {
  tutorId: string;
};

const TutorModal = ({ tutorId }: TutorModalProps) => {
  const { teacher: tutor } = usePublicTeacherProfileQuery(tutorId);
  const hideModal = useModalStore((state) => state.hideModal);

  const tutorSubjects = useMemo(() => {
    if (tutor?.tutoring_information == null) return;

    return Object.keys(tutor?.tutoring_information)
      .filter((value: Subject) => tutor?.tutoring_information[value]?.length > 0)
      .map((value) => startCase(value))
      .join(", ");
  }, [tutor]);

  const experienceYears = tutor?.teaching_experience_years;

  const tutorGrades = useMemo(() => {
    if (tutor?.tutoring_information) {
      const grades = Object.keys(tutor?.tutoring_information)
        .map((value: Subject) => tutor?.tutoring_information[value])
        .flat();

      return uniq(grades)
        .map((value) => startCase(value))
        .join(", ");
    }

    return null;
  }, [tutor?.tutoring_information]);

  return (
    <ModalWrapper variant="fullscreen" hideCloseButton>
      <ActionIcon
        radius={40}
        size={"lg"}
        variant="light"
        onClick={() => hideModal()}
        sx={{ position: "absolute", left: 40, top: 40 }}
      >
        <ArrowLeft size={24} color="#2C2A50" />
      </ActionIcon>

      {tutor ? (
        <div sx={sxWrapper}>
          <div>
            <div sx={sxTutorProfile}>
              {tutor.photo_url && (
                <div sx={sxProfilePhotoWrapper}>
                  <img width="auto" sx={sxProfilePhoto} src={tutor.photo_url} alt="ClassDojo teacher profile" />
                </div>
              )}

              <div sx={{ display: "flex", flexDirection: "column" }}>
                <div sx={sxTutorName}>
                  {tutor.first_name} {tutor.last_name}
                </div>
                <div sx={{ marginBottom: "12px" }}>
                  <div
                    sx={{
                      color: "#7174A0",
                      fontSize: "12px",
                      lineHeight: "14px",
                      letterSpacing: "0.5px",
                      fontWeight: 700,
                      textTransform: "uppercase",
                    }}
                  >
                    Classdojo certified
                  </div>
                </div>

                {!!experienceYears && experienceYears > 0 && (
                  <div
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "6px",
                      }}
                    >
                      <Star fill={"#FFDC00"} size={15} color="#FFDC00" />
                    </div>
                    <div
                      sx={{
                        color: "#2C2A50",
                        fontSize: "15px",
                        lineHeight: "24px",
                        fontWeight: 700,
                      }}
                    >
                      {experienceYears} years of teaching experience
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div sx={sxTutorContent}>
            <div
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                color: "#2C2A50",
                whiteSpace: "pre-line",
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {tutor.teaching_experience_description}
            </div>
          </div>

          <div sx={sxTutoringInformation}>
            <div sx={{ marginBottom: "24px" }}>
              <div sx={{ display: "flex", alignItems: "center" }}>
                <Image width="auto" mr={4} src="icons/cd-grades.svg" alt="ClassDojo grades icon" />
                <div sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 700 }}>Teaches</div>
              </div>
              <p sx={{ fontSize: "16px", lineHeight: "24px", marginLeft: "26px" }}>{tutorSubjects}</p>
            </div>
            <div>
              <div sx={{ display: "flex", alignItems: "center" }}>
                <Image width="auto" mr={4} src="icons/cd-list.svg" alt="ClassDojo list icon" />
                <div sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 700 }}>Grade</div>
              </div>
              <div sx={{ fontSize: "16px", lineHeight: "24px", marginLeft: "26px" }}>{tutorGrades}</div>
            </div>
          </div>
        </div>
      ) : (
        <div
          sx={{
            minHeight: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
    </ModalWrapper>
  );
};

export default TutorModal;

const sxWrapper: ThemeUIStyleObject = {
  margin: "auto",
  width: "564px",
  minWidth: "564px",
  paddingTop: "dt_l",
  marginTop: "dt_l",

  [`@media (max-width: ${theme.breakpoints.dt_viewport_s})`]: {
    padding: "dt_l",
    width: "100%",
    minWidth: "100%",
  },
};

const sxTutorProfile: ThemeUIStyleObject = {
  textAlign: "center",
};

const sxProfilePhotoWrapper: ThemeUIStyleObject = {
  display: "flex",
  justifyContent: "center",
};

const sxProfilePhoto: ThemeUIStyleObject = {
  width: "120px",
  height: "120px",
  borderRadius: "dt_radius_round",
  objectFit: "cover",

  [`@media (max-width: ${theme.breakpoints.dt_viewport_s})`]: {
    width: "240px",
    height: "240px",
    borderRadius: RAW_cssValue("40px"),
    marginBottom: "dt_l",
  },
  backgroundColor: "dt_background_secondary",
};

const sxTutorName: ThemeUIStyleObject = {
  color: RAW_cssValue("#2C2A50"),
  fontSize: "36px",
  lineHeight: "48px",
  fontWeight: 700,
  marginBottom: "4px",

  [`@media (max-width: ${theme.breakpoints.dt_viewport_s})`]: {
    fontSize: "24px",
    lineHeight: "36px",
  },
};

const sxTutoringInformation: ThemeUIStyleObject = {
  paddingTop: "dt_xl",
  paddingBottom: "dt_xl",
  borderTop: RAW_cssValue("2px solid #EAECF5"),

  [`@media (max-width: ${theme.breakpoints.dt_viewport_s})`]: {
    fontSize: "24px",
    lineHeight: "36px",
    padding: "16px 0",
    flexDirection: "column",
    paddingTop: "dt_l",
    paddingBottom: "dt_l",
  },
};

const sxTutorContent: ThemeUIStyleObject = {
  paddingTop: "dt_xl",
  paddingBottom: "dt_xl",
  display: "flex",
  flexDirection: "column",

  [`@media (max-width: ${theme.breakpoints.dt_viewport_s})`]: {
    paddingTop: "dt_l",
    paddingBottom: "dt_l",
  },
};
