import useStyles from "./styles/WaveBg.styles";
import { Box } from "UILibrary";

const WaveBg = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.purple}>
      <Box className={classes.wave} />
    </Box>
  );
};

export default WaveBg;
