import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { theme } from "@classdojo/web/nessie";

export const sxWrapper: ThemeUIStyleObject = {
  padding: "dt_xxl",
  display: "flex",
  flexDirection: "column",
  gap: "dt_s",
  flexGrow: 1,
  justifyContent: "center",
  backgroundColor: "dt_background_primary",
  borderRadius: "dt_radius_l",
  marginBlock: "dt_m",
  maxWidth: 768,
  minWidth: 350,
  marginInline: "auto",

  "&[data-has-header='true']": {
    paddingTop: 0,
  },

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    padding: "dt_s",
    width: "100%",
    paddingBottom: "dt_xxl",
  },
};

export const sxClickableOption: ThemeUIStyleObject = {
  border: "dt_card",
  height: 48,
  alignItems: "center",
  paddingInline: "dt_m",
  "div:first-of-type": {
    display: "none",
  },
  "&[data-checked='true']": {
    borderColor: "dt_content_accent",
  },
};

export const sxWrapperBackground: ThemeUIStyleObject = {
  display: "flex",
  [`@media (min-width: ${theme.breakpoints.m})`]: {
    backgroundColor: "dt_background_accent",
  },
};

export const sxHorizontalRadioGroup: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "dt_s",
  width: "100%",
  justifyContent: "center",
};

export const sxMoreButton: ThemeUIStyleObject = {
  ...sxClickableOption,
  borderRadius: "dt_radius_s",
  backgroundColor: "dt_background_primary",
  cursor: "pointer",
  fontSize: "18px",
  fontWeight: 600,
};

export const sxAddPill: ThemeUIStyleObject = {
  maxWidth: 480,
  margin: "auto",
  backgroundColor: "dt_background_secondary",
  borderRadius: "dt_radius_m",
  paddingLeft: "dt_s",
  paddingRight: "dt_xl",
  paddingBlock: "dt_xs",
  position: "relative",
  marginTop: "dt_l",
};

export const sxPhoneInput: ThemeUIStyleObject = {
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: "dt_content_tertiary",
  borderRadius: "dt_radius_s",

  "&:focus-within": {
    borderColor: "dt_border_active",
  },

  ".PhoneInputCountry": {
    padding: "dt_m",
    outline: "none",
    gap: "dt_s",
  },

  ".PhoneInputInput": {
    padding: "dt_xs",
    border: "none",
    height: 62,
    backgroundColor: "transparent",
    outline: "none",
    fontWeight: "600",
    fontSize: "18px",
  },
};
