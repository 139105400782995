import { DropdownMenu, ListItem, Space, LabelText, BodyText } from "@classdojo/web/nessie";
// eslint-disable-next-line @web-monorepo/no-nessie-icons
import { UndoIcon } from "@classdojo/web/nessie/icons";
import snakeCase from "lodash/snakeCase";
import { useMemo, useState } from "react";
import { useFetchedFeatureSwitchesWithOverrides } from "../featureSwitches";
import { DevToolsSettings } from "./devToolsSettings";
import { TranslatedString } from "@classdojo/web/pods/i18n/translate";
import assert from "assert";

type FeatureSwitchConfigShape<T extends string> = {
  name: T;
  value: string;
  predefinedValues: string[];
};

export const FeatureSwitchesSectionContainer = ({ windowObject }: { windowObject: typeof window }) => {
  const featureSwitchesValues = useFetchedFeatureSwitchesWithOverrides<string>();

  const {
    hasFeatureSwitchOverrides,
    setFeatureSwitchOverride,
    clearFeatureSwitchOverride,
    clearAllFeatureSwitchOverrides,
  } = DevToolsSettings.useFeatureSwitchOverrides();

  const featureSwitches = useMemo(
    () =>
      Object.entries(featureSwitchesValues).map(([fsName, value]) => {
        return {
          name: fsName,
          value,
          predefinedValues: ["on", "off", "exposure", "control", "test"],
        };
      }),
    [featureSwitchesValues],
  );

  return (
    <FeatureSwitchesSection
      featureSwitches={featureSwitches}
      hasChanges={hasFeatureSwitchOverrides}
      onChange={setFeatureSwitchOverride}
      resetFeatureSwitch={clearFeatureSwitchOverride}
      resetAllFeatureSwitches={clearAllFeatureSwitchOverrides}
      windowObject={windowObject || window}
    />
  );
};

const FeatureSwitchesSection = ({
  featureSwitches,
  hasChanges,
  onChange,
  resetFeatureSwitch,
  resetAllFeatureSwitches,
  windowObject,
}: FeatureSwitchesSectionProps) => {
  const [search, setSearch] = useState("");

  return (
    <div sx={{ display: "flex", flexDirection: "column", flex: 1, position: "relative" }}>
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingBottom: "dt_s",
          position: "relative",
        }}
      >
        <div sx={{ flex: 1, position: "relative" }}>
          <LabelText>
            Feature Switches
            {hasChanges && (
              <>
                <Space kind="inline" size="xs" />
                <BodyText display="inline" kind="danger" level={2}>
                  (has overrides)
                </BodyText>
              </>
            )}
          </LabelText>
        </div>
        <div sx={{ position: "relative" }}>
          <UndoIcon cursor="pointer" color="dt_taro90" onClick={resetAllFeatureSwitches} />
        </div>
      </div>
      <input
        placeholder="search"
        onInput={(event) => {
          assert(event.target instanceof HTMLInputElement);
          setSearch(event.target.value);
        }}
      />
      {featureSwitches && (
        <div sx={{ flex: 1, position: "relative" }}>
          {featureSwitches
            .filter((fs) => !search || fs.name.includes(search))
            .map((fs) => {
              return (
                <FeatureSwitchItem
                  key={fs.name}
                  name={fs.name}
                  value={fs.value}
                  predefinedValues={fs.predefinedValues}
                  onChange={onChange}
                  windowObject={windowObject}
                  resetFeatureSwitch={resetFeatureSwitch}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};
type FeatureSwitchesSectionProps = {
  featureSwitches?: FeatureSwitchConfigShape<string>[];
  hasChanges?: boolean;
  onChange: (name: string, value: string) => void;
  resetFeatureSwitch: (name: string) => void;
  resetAllFeatureSwitches: () => void;
  windowObject: typeof window;
};

const FeatureSwitchItem = ({
  name,
  value,
  predefinedValues,
  onChange,
  windowObject,
  resetFeatureSwitch,
}: FeatureSwitchItemProps) => {
  const menuItems = [
    ...(predefinedValues || []).map((value) => ({
      value,
      onClick: () => onChange(name, value),
      "data-name": value,
    })),
    {
      value: "Custom...",
      onClick: () => {
        const newValue = windowObject.prompt(`Please enter new value for [${name}]`);
        if (newValue) {
          onChange(name, newValue);
        }
      },
    },
  ];

  const menuOptions = menuItems.map((menuItem) => ({
    label: menuItem.value as TranslatedString,
    onClick: menuItem.onClick,
    opensAModal: false,
    "data-name": snakeCase(menuItem.value),
  }));

  return (
    <div
      sx={{
        paddingTop: "dt_s",
        paddingBottom: "dt_s",
        borderBottom: "dt_card",
        position: "relative",
      }}
    >
      <ListItem
        title={<div sx={{ wordBreak: "break-all", fontSize: "18px", fontWeight: "bold" }}>{name}</div>}
        rightAccessory={
          <div sx={{ display: "flex", flexDirection: "row", alignItems: "center", position: "relative" }}>
            <DropdownMenu
              data-name={`feature_switches_section:feature_switch_dropdown_menu:${name}`}
              color={value === "off" ? "dt_taro60" : "dt_aqua60"}
              trigger={<span sx={{ fontSize: "20px" }}>{value}</span>}
              options={menuOptions}
              label={value as TranslatedString}
              caret
            />
            <div sx={{ marginLeft: "dt_s", position: "relative" }}>
              <UndoIcon cursor="pointer" size="s" onClick={() => resetFeatureSwitch(name)} />
            </div>
          </div>
        }
      />
    </div>
  );
};
type FeatureSwitchItemProps = {
  name: string;
  value: string;
  predefinedValues: string[];
  onChange: (name: string, value: string) => void;
  windowObject: typeof window;
  resetFeatureSwitch: (name: string) => void;
};
