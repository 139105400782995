import { FunctionComponent, PropsWithChildren } from "react";
import { generatePath } from "react-router-dom";
import classes from "./button.module.css";

export const Sizes = ["large", "medium", "small"] as const;
export const Variants = [
  "primary",
  "primarySubtle",
  "secondary",
  "tertiary",
  "destructive",
  "destructiveTertiary",
] as const;
export const Widths = ["fit", "fill"] as const;

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

type BaseProps = PropsWithChildren<{
  ["data-name"]: string;
  size?: (typeof Sizes)[number];
  variant?: (typeof Variants)[number];
  width?: (typeof Widths)[number];
  experiments?: string[];
}>;

type ButtonProps = BaseProps & {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: HTMLButtonProps["type"];
  autoFocus?: HTMLButtonProps["autoFocus"];
  experiments?: string[];
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
};

export const ButtonClasses = classes;

export function DDSButton({
  onClick,
  children,
  size = "medium",
  variant = "primary",
  width = "fit",
  disabled = false,
  type = "button",
  ["data-name"]: dataName,
}: ButtonProps) {
  // disabled buttons are considered a variant in the design system. To keep the API nice and semantically similar to normal
  // button components, we expose the disabled prop. We do not pass through a variant to the CSS side so that we don't have
  // to play priority games with the disabled state overriding color and background color:
  const className = [classes.button, classes[size], disabled ? null : classes[variant], classes[width]].join(" ");
  return (
    <button data-name={dataName} type={type} className={className} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Params = Record<string, any>;

type LinkProps<T extends string> = BaseProps &
  (
    | {
        to: T;
        search?: string;
        params?: Params;
      }
    | { href: string }
  );

// Helper type to extract the props of a React component
type ComponentProps<T> = T extends React.ComponentType<infer P> ? P : never;

export function LinkButtonFactory<
  T extends FunctionComponent<
    PropsWithChildren<{
      to: string;
      search?: string;
      params?: Params;
    }>
  >,
>(LinkType: T): FunctionComponent<LinkProps<ComponentProps<T>["to"]>> {
  // I haven't figured out a way to
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Link = LinkType as any;
  return ({ children, size = "medium", variant = "primary", width = "fit", ["data-name"]: dataName, ...rest }) => {
    const className = [classes.button, classes[size], classes[variant], classes[width]].join(" ");

    if ("to" in rest && rest.to) {
      const path = generatePath(
        rest.to,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        "params" in rest ? rest.params : ({} as any),
      );

      return (
        <Link className={className} to={path} search={rest.search} data-name={dataName} experiments={rest.experiments}>
          {children}
        </Link>
      );
    } else {
      return (
        <a
          href={("href" in rest && rest.href) || undefined}
          className={className}
          data-name={dataName}
          data-experiments={rest.experiments}
        >
          {children}
        </a>
      );
    }
  };
}
