import { TutorGrade, TutorSubject, labelAndEmojiBySubject, labelByTutorGrade } from "src/utils/constants";

export const tutorSubjectsByGrade = (grade: TutorGrade): { subject: TutorSubject; label: string; emoji: string }[] => {
  let result: TutorSubject[] = [];
  switch (grade) {
    case "pre-kindergarten":
      result = ["math", "reading", "summer_prep_course"];
      break;
    case "kindergarten":
      result = ["math", "reading", "writing", "summer_prep_course"];
      break;
    case "1st_grade":
      result = ["math", "reading", "writing", "summer_prep_course"];
      break;
    case "2nd_grade":
      result = ["math", "reading", "writing", "summer_prep_course"];
      break;
    case "3rd_grade":
      result = ["math", "reading", "writing", "summer_prep_course"];
      break;
    case "4th_grade":
      result = ["math", "reading", "writing", "summer_prep_course"];
      break;
    case "5th_grade":
      result = ["math", "reading", "writing", "summer_prep_course"];
      break;
    case "6th_grade":
      result = ["math", "reading", "writing", "summer_prep_course"];
      break;
    case "7th_grade":
      result = ["math", "reading", "writing", "summer_prep_course"];
      break;
    case "8th_grade":
      result = ["math", "reading", "writing", "summer_prep_course"];
      break;
    case "9th_grade":
      result = ["math", "reading", "summer_prep_course"];
      break;
  }
  return result.map((subject) => ({
    label: labelAndEmojiBySubject[subject].label,
    subject,
    emoji: labelAndEmojiBySubject[subject].emoji,
  }));
};

export const tutorGradesBySubject = (subject: TutorSubject): { grade: TutorGrade; label: string }[] => {
  // used to list all grades for a given subject, used in tutor profile and teacher profile application
  let result: TutorGrade[] = [];
  switch (subject) {
    case "math":
      result = [
        "pre-kindergarten",
        "kindergarten",
        "1st_grade",
        "2nd_grade",
        "3rd_grade",
        "4th_grade",
        "5th_grade",
        "6th_grade",
        "7th_grade",
        "8th_grade",
        "9th_grade",
      ];
      break;
    case "reading":
      result = [
        "pre-kindergarten",
        "kindergarten",
        "1st_grade",
        "2nd_grade",
        "3rd_grade",
        "4th_grade",
        "5th_grade",
        "6th_grade",
        "7th_grade",
        "8th_grade",
        "9th_grade",
      ];
      break;
    case "writing":
      result = [
        "kindergarten",
        "1st_grade",
        "2nd_grade",
        "3rd_grade",
        "4th_grade",
        "5th_grade",
        "6th_grade",
        "7th_grade",
        "8th_grade",
        "9th_grade",
      ];
      break;
    case "summer_prep_course":
      result = [
        "pre-kindergarten",
        "kindergarten",
        "1st_grade",
        "2nd_grade",
        "3rd_grade",
        "4th_grade",
        "5th_grade",
        "6th_grade",
        "7th_grade",
        "8th_grade",
        "9th_grade",
      ];
      break;
  }
  return result.map((grade) => ({
    label: labelByTutorGrade[grade],
    grade,
  }));
};
