import { LoadingMojo } from "@classdojo/web";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import { useNavigate } from "react-router";
import { logTutorEvent } from "src/utils/log";

const AdditionalSingleSessionSuccess = () => {
  const push = useNavigate();
  const [state, dispatch] = useGlobalStore();

  useOnFirstRender(() => {
    if (state.booking) {
      logTutorEvent({
        eventName: "parent.marketplace.logged_in_trial_booking.success",
        metadata: {
          card: state.booking.isNewPaymentCard ? "new" : "old",
        },
      });
      dispatch({
        type: actionTypes.UPDATE_BOOKING,
        payload: null,
      });
    }
    setTimeout(() => {
      push("/parents/classes");
    }, 5000);
  });

  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "40px",
      }}
    >
      <LoadingMojo border />
    </div>
  );
};

export default AdditionalSingleSessionSuccess;
