import { CheckboxProps, Checkbox } from "@mantine/core";
import React from "react";
import marketplaceTheme from "src/utils/marketplaceTheme";

const CustomCheckbox: React.FC<CheckboxProps> = (props) => {
  const rootStyles = props.checked
    ? {
        borderColor: marketplaceTheme.colors.dt_border_active,
        backgroundColor: marketplaceTheme.colors.dt_background_accent,
        boxShadow: "0px 2px 0px rgba(45, 64, 150, 0.06)",
      }
    : {};

  const rootStylesDisabled = props.disabled
    ? {
        borderColor: "#D3D7EC",
        backgroundColor: "#F7F8FF",
        boxShadow: "none",
      }
    : {};

  return (
    // @ts-expect-error - styles error
    <Checkbox
      labelPosition="left"
      styles={{
        root: {
          flexDirection: "row-reverse",
          paddingLeft: "16px",
          paddingRight: "16px",
          borderRadius: "32px",
          height: "48px",
          border: "2px solid #D3D7EC",
          boxShadow: "0px 6px 0px rgba(45, 64, 150, 0.06)",
          ...rootStyles,
          ...rootStylesDisabled,
        },
        body: {
          alignItems: "center",
          height: "100%",
        },
        labelWrapper: {
          paddingLeft: 0,
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          flex: 1,
          color: props.disabled ? "#D3D7EC" : "#2C2A50",
        },
        input: {
          borderRadius: "8px",
          ":disabled": {
            background: "#D3D7EC",
            borderColor: "#AAB0D8",
            borderWidth: "2px",
          },
        },
      }}
      {...props}
    />
  );
};

export default CustomCheckbox;
