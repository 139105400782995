import { Textarea, TextareaProps } from "@mantine/core";
import React from "react";
import marketplaceTheme from "src/utils/marketplaceTheme";

const CustomTextArea: React.FC<TextareaProps> = (props) => {
  return (
    // @ts-expect-error - styles error
    <Textarea
      styles={() => ({
        input: {
          height: "64px",
          padding: "20px 16px",
          border: "2px solid #D3D7EC",
          borderRadius: "16px",
          color: "#2C2A50",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          "::placeholder": {
            color: "#AAB0D8",
          },
          ":hover": {
            borderColor: "#7174A0",
          },
          ":active": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
          ":focus": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
        },
      })}
      {...props}
    />
  );
};

export default CustomTextArea;
