import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { forwardRef, useImperativeHandle, useEffect } from "react";

export interface RichTextProps {
  value: string;
  onChange: (value: string) => void;
  controls?: (keyof typeof RichTextEditor)[][];
}

const RichText = forwardRef<{ focus: () => void }, RichTextProps>(({ value, onChange, controls }, ref) => {
  const editor = useEditor({
    extensions: [StarterKit, Link, TextAlign.configure({ types: ["heading", "paragraph"] }), Underline],
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  useImperativeHandle(ref, () => ({
    focus: () => editor?.chain().focus().run(),
  }));

  if (!editor) {
    return null;
  }

  return (
    <RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar>
        {controls?.map((group, index) => (
          <RichTextEditor.ControlsGroup key={index}>
            {group.map((control) => {
              const Control = RichTextEditor[control];
              return <Control key={control} color={""} colors={[]} />;
            })}
          </RichTextEditor.ControlsGroup>
        ))}
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content
        onClick={() => {
          editor.chain().focus().run();
        }}
        sx={{
          "& .mantine-RichTextEditor-content": {
            minHeight: "200px",
            padding: "6px 0",
          },
        }}
      />
    </RichTextEditor>
  );
});

export default RichText;
