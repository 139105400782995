import { ChevronDownIcon } from "@classdojo/web/nessie/components/iconsV2/output";
import { CSSObject, Select, SelectProps } from "@mantine/core";
import type { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import useStyles from "./styles/SelectInput.styles";

type SelectInputProps = Omit<SelectProps, "sx"> & {
  inputStyles?: CSSObject;
  sx?: ThemeUIStyleObject;
};

const SelectInput = ({ inputStyles, sx, ...props }: SelectInputProps) => {
  const { classes } = useStyles();

  return (
    <Select
      {...props}
      rightSection={<ChevronDownIcon size="s" color="dt_content_tertiary" />}
      classNames={classes}
      rightSectionWidth={40}
      styles={{ input: { ...inputStyles }, label: { marginBottom: "8px" } }}
    />
  );
};

export default SelectInput;
