import { AxiosError, AxiosInstance } from "axios";
import { logTutorEvent } from "src/utils/log";

const DEFAULT_URL = "DEFAULT_URL";

type QueueItem = {
  url?: string;
  resolve: () => void;
};

type QueryLookupItem = {
  timeslotRequests: number;
  queue: QueueItem[];
};

const queryLookup: Record<string, QueryLookupItem> = {};

function getQueryLookupItem(url?: string): QueryLookupItem {
  const key = url ?? DEFAULT_URL;
  return (queryLookup[key] = queryLookup[key] ?? { timeslotRequests: 0, queue: [] });
}

export default function axiosRateLimit(
  axiosInstance: AxiosInstance,
  errorHandler: (error: AxiosError) => Promise<void>,
  limitByEndpoint = true,
) {
  const maxRequestsPerSecond = 5;

  const shift = (url?: string) => {
    const queryItem = getQueryLookupItem(limitByEndpoint ? url : undefined);
    if (queryItem.queue.length < 1) return;
    if (queryItem.timeslotRequests === maxRequestsPerSecond) {
      logTutorEvent({
        eventName: "tutor.marketplace.request_limit_exceeded",
        metadata: { url },
      });
      return;
    }

    queryItem.queue.shift()?.resolve();

    if (queryItem.timeslotRequests === 0) {
      setTimeout(() => {
        queryItem.timeslotRequests = 0;
        shift();
      }, 1000);
    }

    ++queryItem.timeslotRequests;
  };

  axiosInstance.interceptors.request.use(
    (request) =>
      new Promise((resolve) => {
        const queryItem = getQueryLookupItem(limitByEndpoint ? request.url : undefined);
        queryItem.queue.push({ resolve: () => resolve(request), url: request.url });
        requestAnimationFrame(() => shift(request.url));
      }),
    errorHandler,
  );

  axiosInstance.interceptors.response.use((response) => {
    shift(response.request.url);
    return response;
  }, errorHandler);

  return axiosInstance;
}
