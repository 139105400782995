import { BodyText, HeadlineText, LabelText } from "@classdojo/web/nessie";
import { Avatar, Image, Loader } from "UILibrary";
import React from "react";
import { useGlobalStore } from "old/src/store";
import type { Teacher } from "src/api/teacher/getTeacherPublicProfile";
import { getSubjectLabel } from "src/utils/constants";
import { formatWithPattern, addMinutes, parseWithPattern, getLocalizedDateTime } from "src/utils/dates";

interface BookingDetailsSimplicityWithTutorProps {
  teacher: Teacher;
  title: string;
  price?: number;
  isFetchingPromoCode: boolean;
}

const getDisplayTeacherName = (teacher: any) => {
  if (teacher?.first_name && teacher?.last_name) {
    return `${teacher?.first_name} ${teacher?.last_name.slice(0, 1)}`;
  } else if (teacher?.first_name) {
    return `Teacher ${teacher?.first_name}`;
  } else if (teacher?.last_name) {
    return `Teacher ${teacher?.last_name}`;
  } else {
    return "ClassDojo Teacher";
  }
};

const getDisplayDateTime = (date: string, time: string) => {
  return formatWithPattern(getLocalizedDateTime(date, time), "eee MMM d, h:mm a");
};

const BookingDetails: React.FC<BookingDetailsSimplicityWithTutorProps> = ({
  teacher,
  title,
  price = 1000,
  isFetchingPromoCode,
}) => {
  const [state] = useGlobalStore();
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        paddingTop: "dt_m",
      }}
    >
      <div>
        <HeadlineText as="h2">{title}</HeadlineText>
      </div>
      <div sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        <div sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div>
            {getDisplayTeacherName(teacher)}, certified {getSubjectLabel(state.booking?.subject)} teacher
          </div>
          <div>
            {getDisplayDateTime(state.booking?.date ?? "", state.booking?.time ?? "")} -{" "}
            {formatWithPattern(addMinutes(parseWithPattern(state?.booking?.time, ["h:mm a"]), 25), "h:mm a")}
          </div>
        </div>
        <div>
          {teacher?.photo_url ? (
            <Image
              styles={{
                image: {
                  background: "dt_backgroung_secondary",
                  objectFit: "cover",
                },
              }}
              width={42}
              height={42}
              radius={160}
              mb={12}
              src={teacher?.photo_url}
              alt="ClassDojo teacher profile"
            />
          ) : (
            <Avatar size={42} radius={24} />
          )}
        </div>
      </div>
      <div>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "dt_xs",
            backgroundColor: "dt_background_tertiary",
            borderRadius: "dt_radius_xs",
            padding: "dt_s",
            marginTop: "dt_s",
          }}
        >
          <div sx={{ display: "flex", justifyContent: "space-between" }}>
            <LabelText level={1}>Total cost</LabelText>
            {isFetchingPromoCode ? (
              <Loader size="xs" />
            ) : (
              <LabelText level={1}>
                <del sx={{ fontWeight: 300, fontSize: "15px" }}>$30</del> ${price / 100}
              </LabelText>
            )}
          </div>
          <BodyText level={2}>Only 2 spots left at this price</BodyText>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
