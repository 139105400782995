import { BodyText, HeadlineText, LabelText, theme } from "@classdojo/web/nessie";
import { RAW_cssValue } from "@classdojo/web/nessie/stylingLib";
import { UseFormReturnType } from "@mantine/form";
import {
  ActionIcon,
  Avatar,
  BaseCheckbox,
  Button,
  Camera,
  Checkbox,
  Divider,
  Image,
  TextArea,
  TextInput,
  Upload,
} from "UILibrary";
import startCase from "lodash/startCase";
import PhotoUploadModal from "old/src/components/PhotoUploadModal";
import VideoUploadModal from "old/src/components/VideoUploadModal";
import { usePrivateTeacherProfileQuery } from "old/src/hooks/useTeacher";
import { Dispatch, SetStateAction, useState } from "react";
import { TutorSubject } from "src/utils/constants";
import { tutorGradesBySubject } from "src/utils/tutorGradeAndSubject";
import { FormValues } from "./FormValues";

interface PropTypes {
  close: () => void;
  loading: boolean;
  form: UseFormReturnType<FormValues>;
  mathEnabled: boolean;
  setMathEnabled: (isEnabled: boolean) => void;
  readingEnabled: boolean;
  setReadingEnabled: (isEnabled: boolean) => void;
  writingEnabled: boolean;
  setWritingEnabled: (isEnabled: boolean) => void;
  onUpdateTeacherProfile: (values: any) => Promise<void>;
}

const EditingProfile = ({
  close,
  loading,
  form,
  mathEnabled,
  setMathEnabled,
  readingEnabled,
  setReadingEnabled,
  writingEnabled,
  setWritingEnabled,
  onUpdateTeacherProfile,
}: PropTypes) => {
  const [photoModal, setPhotoModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const { teacher, refetch } = usePrivateTeacherProfileQuery();

  const specializations = form.getInputProps("specializations").value || {};

  const renderCheckboxGrades = (type: TutorSubject) => {
    let disabled = false;

    switch (type) {
      case "math":
        disabled = mathEnabled;
        break;
      case "reading":
        disabled = readingEnabled;
        break;

      case "writing":
        disabled = writingEnabled;
        break;
    }

    return tutorGradesBySubject(type).map(({ grade }) => (
      <Checkbox disabled={!disabled} key={grade} value={grade} label={startCase(grade)} />
    ));
  };

  return (
    <>
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "dt_m",
        }}
      >
        <HeadlineText as="h2">Profile</HeadlineText>
        <div sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button sx={{ height: "52px" }} onClick={close} variant="white" disabled={loading}>
            Cancel
          </Button>
          <Button sx={{ height: "52px" }} disabled={loading} loading={loading} type="submit" form="updateForm">
            Save edits
          </Button>
        </div>
      </div>

      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: RAW_cssValue("0px 2px 0px rgba(45, 64, 150, 0.06)"),
          border: "dt_card",
          borderRadius: "dt_radius_s",
          background: "#FFF",
          overflow: "hidden",
          padding: "dt_m",
        }}
      >
        <form id="updateForm" onSubmit={form.onSubmit(onUpdateTeacherProfile)}>
          <div
            sx={{
              display: "grid",
              gridTemplateColumns: "200px 1fr",
              columnGap: RAW_cssValue("40px"),
              [`@media (max-width: ${theme.breakpoints.m})`]: {
                gridTemplateColumns: "auto",
                gridTemplateRows: "auto",
                gap: "dt_m",
              },
            }}
          >
            <div sx={{ position: "relative", width: "200px" }}>
              {teacher?.photo_url ? (
                <Image
                  styles={{
                    image: {
                      background: "#F7F8FF",
                    },
                  }}
                  width={200}
                  height={200}
                  radius={24}
                  src={teacher?.photo_url}
                  alt="ClassDojo teacher profile"
                />
              ) : (
                <Avatar size={200} radius={24} />
              )}
              <ActionIcon
                onClick={() => setPhotoModal(true)}
                radius={16}
                color="purple"
                variant="filled"
                size={56}
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  bottom: 50,
                  right: 10,
                }}
              >
                <Camera size={24} />
              </ActionIcon>
              <BodyText level={2} sx={{ fontWeight: "normal" }}>
                Please upload an image in .jpg or .png format
              </BodyText>
            </div>
            <div>
              <div sx={{ display: "flex", gap: "dt_s" }}>
                <TextInput sx={{ flexGrow: 1 }} mb={16} label="First name" {...form.getInputProps("first_name")} />
                <TextInput sx={{ flexGrow: 1 }} label="Last name" {...form.getInputProps("last_name")} />
              </div>
              <TextInput
                mb={6}
                label="Display name"
                placeholder="e.g. Mrs Smith"
                {...form.getInputProps("display_name")}
              />
              <BodyText level={2} sx={{ fontWeight: "normal", color: "dt_content_secondary" }}>
                What you want to be referred to as
              </BodyText>
            </div>
          </div>
          <Divider color="#EAECF5" my={24} />
          <div sx={{ display: "flex", flexDirection: "column", gap: "dt_s" }}>
            <LabelText level={1}>Please select which subjects and grades you want to tutor</LabelText>
          </div>
          <div sx={{ border: "dt_card", marginTop: "dt_l" }}>
            <div
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                [`@media (max-width: ${theme.breakpoints.m})`]: {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              {(
                [
                  { key: "math", label: "Math", enabled: mathEnabled, onChange: setMathEnabled },
                  { key: "reading", label: "Reading", enabled: readingEnabled, onChange: setReadingEnabled },
                  { key: "writing", label: "Writing", enabled: writingEnabled, onChange: setWritingEnabled },
                ] as {
                  key: TutorSubject;
                  label: string;
                  enabled: boolean;
                  onChange: Dispatch<SetStateAction<boolean>>;
                }[]
              ).map((subject, index) => (
                <div
                  key={subject.key}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRight: index < 3 ? "dt_card" : "none",
                    [`@media (max-width: ${theme.breakpoints.m})`]: {
                      borderRight: "none",
                      borderBottom: index < 2 ? "dt_card" : "none",
                    },
                  }}
                >
                  <div
                    sx={{
                      borderBottom: "dt_card",
                      padding: "dt_m",
                      [`@media (max-width: ${theme.breakpoints.m})`]: {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <Checkbox
                      checked={subject.enabled}
                      label={subject.label}
                      onChange={(event) => {
                        subject.onChange(event.currentTarget.checked);
                        !event.currentTarget.checked && form.setFieldValue(subject.key, []);
                      }}
                    />
                  </div>
                  <BaseCheckbox.Group
                    sx={{
                      padding: "dt_m",
                      [`@media (max-width: ${theme.breakpoints.m})`]: {
                        paddingLeft: "dt_xxl",
                        paddingTop: 0,
                        display: subject.enabled ? "flex" : "none",
                      },
                      gap: "20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    error={null}
                    {...form.getInputProps(subject.key)}
                  >
                    {renderCheckboxGrades(subject.key)}
                  </BaseCheckbox.Group>
                </div>
              ))}
            </div>
          </div>
          <Divider color="#EAECF5" my={24} />
          <div sx={{ display: "flex", flexDirection: "column", gap: "dt_xs" }}>
            <LabelText level={1}>Select additional specializations you have experience in</LabelText>
            <BodyText level={2} sx={{ fontWeight: "normal" }}>
              I am willing to tutor students who require additional support for:{" "}
            </BodyText>
          </div>
          <div
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(specializations).map((title) => (
              <div
                key={title}
                sx={{
                  padding: "dt_s",
                  flex: "1 0 25%",
                  maxWidth: "25%",
                  minWidth: "250px",
                }}
              >
                <Checkbox
                  checked={form.getInputProps("specializations").value[title]}
                  label={title}
                  onChange={(event) => {
                    form.setFieldValue(`specializations.${title}`, event.currentTarget.checked);
                  }}
                />
              </div>
            ))}
          </div>
          <Divider color="#EAECF5" my={24} />
          <TextArea
            label={
              <LabelText level={1} sx={{ marginBottom: "dt_xs" }}>
                Share something about yourself and your teaching experience
              </LabelText>
            }
            autosize
            minRows={2}
            {...form.getInputProps("teaching_experience_description")}
          />
          <Divider color="#EAECF5" my={24} />
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              [`@media (max-width: ${theme.breakpoints.m})`]: { flexDirection: "column", gap: "dt_m" },
            }}
          >
            <div sx={{ display: "flex", flexDirection: "column", gap: "dt_s" }}>
              <LabelText>Upload a video presentation for your profile</LabelText>
              <BodyText level={2} sx={{ fontWeight: "normal" }}>
                This will help the parents get to know you even better
              </BodyText>
            </div>

            <Button onClick={() => setVideoModal(true)} variant="white" rightIcon={<Upload />}>
              Upload video
            </Button>
          </div>
        </form>
      </div>

      <PhotoUploadModal opened={photoModal} onClose={() => setPhotoModal(false)} refetch={refetch} />
      <VideoUploadModal opened={videoModal} onClose={() => setVideoModal(false)} refetch={refetch} />
    </>
  );
};

export default EditingProfile;
