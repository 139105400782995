import GetStarted from "src/pageComponents/Landing/GetStarted";
import { useLogTutorEventOnFirstRender } from "src/utils/log";

const Save20 = () => {
  useLogTutorEventOnFirstRender({
    eventName: "parent.marketplace.save20.load",
  });

  return <GetStarted showTopCTA={true} />;
};

export default Save20;
