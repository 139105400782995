import { BodyText, MinutiaeText, LabelText } from "@classdojo/web/nessie";
// eslint-disable-next-line @web-monorepo/no-nessie-icons
import { XCircleIcon, ChevronDownIcon, ChevronUpIcon, HelpIcon } from "@classdojo/web/nessie/icons";
import { useState } from "react";
import { addOnEventsChangeHandler, Event, flush } from "./eventRecorder";
import { format } from "@web-monorepo/dates";
import useSideEffectOnMount from "@classdojo/web/hooks/useSideEffectOnMount";

export const EventLogHistorySectionContainer = () => {
  const [eventsLog, setEventsLog] = useState<Event[]>([]);

  // register on mount to update the events list whenever the app sends a new event

  useSideEffectOnMount(() => {
    return addOnEventsChangeHandler((events) => {
      setEventsLog([...events.reverse()]);
    });
  });

  return <EventLogHistorySection eventsLog={eventsLog} clearEvents={flush} />;
};

// https://primer.style/components/relative-time
export function untranslatedFromNow(date: Date, skipSentence?: boolean): string {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  const seconds = Math.round(diff / 1000);

  if (Math.abs(seconds) < 60) {
    return "just now";
  }
  const minutes = Math.floor(seconds / 60);
  if (Math.abs(minutes) < 60) {
    return new Intl.RelativeTimeFormat("en", { numeric: "auto" }).format(minutes, "minute");
  }
  const hours = Math.round(seconds / 3600);
  if (Math.abs(hours) < 24) {
    return new Intl.RelativeTimeFormat("en", { numeric: "auto" }).format(hours, "hour");
  }
  const days = Math.round(hours / 24);
  if (Math.abs(days) < 28) {
    return new Intl.RelativeTimeFormat("en", { numeric: "auto" }).format(days, "day");
  }

  const dateString = format(date, { month: "short", day: "2-digit", year: days >= 365 ? "numeric" : undefined });
  if (skipSentence) return dateString;

  return `on ${dateString}`;
}

type EventLogHistorySectionProps = {
  eventsLog: Event[];
  clearEvents: VoidFunction;
};

const EventLogHistorySection = ({ eventsLog, clearEvents }: EventLogHistorySectionProps) => {
  return (
    <div sx={{ display: "flex", flexDirection: "column", flex: 1, position: "relative" }}>
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingBottom: "dt_s",
          position: "relative",
        }}
      >
        <div sx={{ flex: 1, position: "relative" }}>
          <LabelText>Logged Events</LabelText>
        </div>
        <div sx={{ position: "relative" }}>
          <XCircleIcon cursor="pointer" onClick={clearEvents} />
        </div>
      </div>
      <div sx={{ flex: 1, position: "relative" }}>
        {eventsLog.map((event: Event) => {
          // highlight events logged in the last 5 seconds before this rendering cycle,
          // this will only update when the list gets re-rendered, so events will stay highlighted
          // for longer than 5 seconds, but that should be good enough for now
          const isRecentEvent = new Date().getTime() - event.timestamp.getTime() < 5000;
          return <EventLogHistoryItem key={event._id} event={event} isRecentEvent={isRecentEvent} />;
        })}
      </div>
      <div
        sx={{
          marginTop: "dt_m",
          padding: "dt_xs",
          boxShadow: "dt_shadow_shadezies",
          border: "dt_card",
          display: "flex",
          flexDirection: "row",
          color: "#5D5D8F",
          position: "relative",
        }}
      >
        <HelpIcon />
        <div sx={{ marginLeft: "dt_xs", position: "relative" }}>
          <BodyText level={2}>
            Toolkit runs inside an iframe, therefore we don't have access to logged events. If you need to see Toolkit
            events, open the network tab of your developer toolbar.
          </BodyText>
        </div>
      </div>
    </div>
  );
};

type EventLogHistoryItemProps = {
  event: Event;
  isRecentEvent?: boolean;
};

const EventLogHistoryItem = ({ event, isRecentEvent }: EventLogHistoryItemProps) => {
  const [isShowingDetails, setIsShowingDetails] = useState(false);

  return (
    <div
      sx={{
        paddingTop: "dt_s",
        paddingBottom: "dt_s",
        borderBottom: "dt_card",
        borderColor: isRecentEvent ? "#13803A" : "#5D5D8F",
        position: "relative",
      }}
    >
      <div sx={{ display: "flex", alignItems: "center", flexDirection: "row", position: "relative" }}>
        <div
          onClick={() => setIsShowingDetails((value) => !value)}
          sx={{ flex: 1, cursor: "pointer", position: "relative" }}
          // eslint-disable-next-line @web-monorepo/no-jsx-role-button
          role="button"
          tabIndex={0}
        >
          <MinutiaeText kind="disabled">{untranslatedFromNow(event.timestamp)}</MinutiaeText>
          <BodyText>{event.details.eventName}</BodyText>
          {event.details.eventValue ? (
            <div sx={{ display: "flex", flexDirection: "row", position: "relative" }}>
              <div sx={{ marginRight: "dt_xs", position: "relative" }}>
                <MinutiaeText kind="disabled">value:</MinutiaeText>
              </div>
              <div sx={{ position: "relative" }}>
                <MinutiaeText kind="tertiary">{event.details.eventValue}</MinutiaeText>
              </div>
            </div>
          ) : null}
        </div>
        <div sx={{ marginLeft: "dt_s", position: "relative" }}>
          <div sx={{ marginLeft: "dt_s", position: "relative" }}>
            {isShowingDetails ? <ChevronUpIcon size="s" /> : <ChevronDownIcon size="s" />}
          </div>
        </div>
      </div>
      {isShowingDetails ? (
        <div
          sx={{
            marginTop: "dt_s",
            paddingLeft: "dt_s",
            paddingRight: "dt_s",
            backgroundColor: "#F1F3F8",
            border: "dt_card",
            borderRadius: "dt_radius_s",
            fontSize: "12px",
            position: "relative",
          }}
        >
          <pre>{JSON.stringify(event.details, null, 2)}</pre>
        </div>
      ) : null}
    </div>
  );
};
